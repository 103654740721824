import type { VehicleRegNumberState } from 'types';
import type { Reducer } from 'reducers/types';
import type {
  FetchVehicleRegNumberType,
  FetchVehicleRegNumberSuccessType,
  FetchVehicleRegNumberFailType,
  UnsetVehicleRegNumberRedirectType,
  ResetVehicleRegNumberRedirectType
} from 'actions/vehicleRegNumber/types';

import {
  FETCH_VEHICLE_REG_NUMBER,
  FETCH_VEHICLE_REG_NUMBER_SUCCESS,
  FETCH_VEHICLE_REG_NUMBER_FAIL,
  RESET_VEHICLE_REG_NUMBER,
  UNSET_VEHICLE_REG_NUMBER_REDIRECT
} from 'actions/vehicleRegNumber/actionTypes';
import { initState } from 'reducers';

type ActionType =
  | FetchVehicleRegNumberType
  | FetchVehicleRegNumberSuccessType
  | FetchVehicleRegNumberFailType
  | UnsetVehicleRegNumberRedirectType
  | ResetVehicleRegNumberRedirectType;

const vehicleRegNumberReducer: Reducer<
  VehicleRegNumberState,
  {
    type: ActionType,
    payload: { data?: { [key: string]: any }, error?: null | string }
  }
> = (
  state: VehicleRegNumberState = initState.vehicleRegNumber,
  { type, payload }
) => {
  switch (type) {
    case RESET_VEHICLE_REG_NUMBER:
      return initState.vehicleRegNumber;

    case FETCH_VEHICLE_REG_NUMBER:
      return {
        ...state,
        isLoading: true,
        fetchComplete: false
      };

    case FETCH_VEHICLE_REG_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        redirectPermitted: true,
        data: payload.data,
        error: null
      };

    case FETCH_VEHICLE_REG_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        redirectPermitted: true,
        error: payload.error
      };

    case UNSET_VEHICLE_REG_NUMBER_REDIRECT:
      return {
        ...state,
        redirectPermitted: false
      };

    default:
      return state;
  }
};

export default vehicleRegNumberReducer;
