import type { UseTill } from 'hooks/useTill/types';

import { useContext } from 'react';
import { isEmpty } from 'lodash/fp';
import TillProvider from 'providers/partsFinderTill';

import { PARTS_FINDER_TILL } from 'libs/constants';
import { fromJSON } from 'libs/json';

const useTill: UseTill = () => {
  const { till, ...contextProps } = useContext(TillProvider);

  return {
    loggedIn: !isEmpty(fromJSON(sessionStorage.getItem(PARTS_FINDER_TILL))),
    till,
    ...contextProps
  };
};

export default useTill;
