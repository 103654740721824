import type { AppState, SearchDomainState } from 'types';
import type { Selector } from 'selectors/types';
import type {
  IndexReferenceSelector,
  DomainSelector
} from 'selectors/searchDomain/types';

import { createSelector } from 'reselect';

const selectIndexReference = state => state.searchDomain.indexReference;
const selectDomain = state => state.searchDomain.domain;
const selectSearchDomain = state => state.searchDomain;

export const indexReferenceSelector: IndexReferenceSelector = createSelector(
  selectIndexReference,
  indexReference => indexReference
);

export const domainSelector: DomainSelector = createSelector(
  selectDomain,
  domain => domain
);

export const searchDomainSelector: Selector<
  AppState,
  SearchDomainState
> = createSelector(
  selectSearchDomain,
  searchDomain => searchDomain
);
