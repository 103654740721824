import type {
  SetPreviousVehicleLookupLocationActionType,
  ResetPreviousVehicleLookupLocationActionType
} from 'actions/vehicleChangePreviousLocation/types';

export const SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION: SetPreviousVehicleLookupLocationActionType =
  '@@app/sidebar/SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION';

export const RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION: ResetPreviousVehicleLookupLocationActionType =
  '@@app/sidebar/RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION';
