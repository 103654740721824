import type {
  FetchSearchWithPayloadAction,
  SearchAction,
  FetchSearchAction
} from 'actions/search/types';

import { isEmpty } from 'lodash/fp';
import {
  fetchProductsSearch,
  fetchWildSearch,
  fetchPartNumbersSearch
} from 'api/products';
import {
  SET_SEARCH_FILTER,
  SET_SEARCH_TERM,
  RESET_SEARCH,
  SET_SEARCH_SELECTION,
  FETCH_SEARCH_FIELD,
  CLEAR_SEARCH_SORT_FILTERS,
  CLEAR_SEARCH_FILTER,
  FETCH_SEARCH_FIELD_SUCCESS,
  FETCH_SEARCH_FIELD_FAIL
} from 'actions/search/actionTypes';

const resetSearch = () => ({
  type: RESET_SEARCH
});

const clearSearchSortFilters = () => ({
  type: CLEAR_SEARCH_SORT_FILTERS
});

const clearSearchFilter: FetchSearchAction = () => ({
  type: CLEAR_SEARCH_FILTER
});

const fetchSearchStart: FetchSearchWithPayloadAction = payload => ({
  type: FETCH_SEARCH_FIELD,
  payload
});

const fetchSearchSuccess: FetchSearchWithPayloadAction = payload => ({
  type: FETCH_SEARCH_FIELD_SUCCESS,
  payload
});

const fetchSearchFail: FetchSearchWithPayloadAction = payload => ({
  type: FETCH_SEARCH_FIELD_FAIL,
  payload
});

const setSearchTerm: FetchSearchWithPayloadAction = payload => ({
  type: SET_SEARCH_TERM,
  payload
});

const setSearchSelection: FetchSearchWithPayloadAction = payload => ({
  type: SET_SEARCH_SELECTION,
  payload
});

const setSearchFilter: FetchSearchWithPayloadAction = payload => ({
  type: SET_SEARCH_FILTER,
  payload
});

const search: SearchAction = args => (dispatch, getState) => {
  dispatch(
    fetchSearchStart({
      active: args.search
    })
  );

  const {
    isProductSearch,
    sort,
    pageChange,
    pageResultChange,
    filter: filterProps,
    ...searchArgs
  } = args;

  const fetcher = isProductSearch
    ? (!!searchArgs.partNumbers
      ? fetchPartNumbersSearch
      : fetchProductsSearch)
    : fetchWildSearch;

  fetcher(searchArgs)
    .then(result => {
      const {
        search: { brandNames, vendors, filter }
      } = getState();

      const { total, skipped, products, brands, sapVendors } = result;
      dispatch(
        fetchSearchSuccess({
          products,
          total,
          skipped,
          brandNames: isEmpty(brandNames) ? brands : brandNames,
          vendors: isEmpty(vendors) ? sapVendors : vendors,
          active: args.search,
          current: args.page,
          pageTotal: args.size,
          filter: !!filterProps ? filterProps : filter,
          sort,
          pageChange,
          pageResultChange
        })
      );
    })
    .catch(error => {
      dispatch(fetchSearchFail({ error: error.message }));
    });
};

export {
  search as default,
  resetSearch,
  setSearchTerm,
  setSearchSelection,
  setSearchFilter,
  clearSearchSortFilters,
  clearSearchFilter
};
