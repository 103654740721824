import type { ObjectShape } from 'types';

import axios from 'axios';
import qs from 'query-string';

export const executeFetch = async (
  path: string,
  options: ObjectShape | Object = {}
): Promise<any> => {
  if (!!process.env && !!process.env.REACT_APP_BASE_ENDPOINT) {
    return axios
      .get(`//${process.env.REACT_APP_BASE_ENDPOINT}${path}`, {
        params: { ...options },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(async ({ data, status }) => {
        switch (status) {
          case 400:
            throw new Error('Bad request');
          case 404:
            throw new Error('Endpoint not found');
          case 500:
            throw new Error('Service unavailable');
          default:
            break;
        }

        return data;
      })
      .catch(({ response, request, config, message }) => {
        throw new Error(message);
      });
  }
};
