import { createSelector } from 'reselect';
import type {
  VehicleRegNumberLoadingSelector,
  VehicleRegNumberErrorSelector,
  VehicleRegNumberSelector,
  VehicleRegNumberFetchCompleteSelector
} from 'selectors/vehicleRegNumber/types';

const selectLoading = state => state.vehicleRegNumber.isLoading;
const selectLFetchComplete = state => state.vehicleRegNumber.fetchComplete;
const selectError = state => state.vehicleRegNumber.error;
const selectVehicleRegNumberData = state => state.vehicleRegNumber.data;
const selectVehicleRegNumberRedirect = state =>
  state.vehicleRegNumber.redirectPermitted;

export const loadingSelector: VehicleRegNumberLoadingSelector = createSelector(
  selectLoading,
  isLoading => isLoading
);

export const fetchingCompleteSelector: VehicleRegNumberFetchCompleteSelector = createSelector(
  selectLFetchComplete,
  isFetchComplete => isFetchComplete
);

export const errorSelector: VehicleRegNumberErrorSelector = createSelector(
  selectError,
  error => error
);

export const vehicleRegNumberSelector: VehicleRegNumberSelector = createSelector(
  selectVehicleRegNumberData,
  data => data
);

export const redirectSelector: VehicleRegNumberSelector = createSelector(
  selectVehicleRegNumberRedirect,
  redirectPermitted => redirectPermitted
);
