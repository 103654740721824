import type { AppState, NavigationCategory } from 'types';
import type { ActiveNav } from 'selectors/navigation/types';
import type { Selector } from 'selectors/types';
import type { SearchDomain } from 'config/searchDomain/types';

import { createSelector } from 'reselect';
import { toLower, pipe } from 'lodash/fp';
import searchDomain, {
  NO_INDEX_REFERENCE,
  MANUAL_CYCLE_LOOKUP_NODES
} from 'config/searchDomain';
import trimSpace from 'libs/trimSpace';

const selectActiveNavItems = state => state.navigation.active;
const selectActiveIndexReference = state => state.navigation.indexReference;
const selectLoading = state => state.navigation.isLoading;
const selectError = state => state.navigation.error;
const selectMenu = state => state.navigation.data;

export const selectedPillarLevelSelector: Selector<
  AppState,
  NavigationCategory
> = createSelector(
  [selectActiveNavItems, selectMenu],
  (active, navigation) => {
    const [pillar] = active;

    return navigation.find(nav => nav.categoryId === pillar.category);
  }
);

export const selectedPillarLevelCategorySelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectedPillarLevelSelector],
  pillar =>
    !!pillar
      ? searchDomain.find(
          domain => toLower(domain.domain) === toLower(pillar.nodeName)
        )
      : null
);

export const selectedTopLevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedPillarLevelSelector],
  (active, pillarLevel) => {
    if (!pillarLevel) {
      return null;
    }

    return pillarLevel.childCategories.length
      ? pillarLevel.childCategories.find(
          cat => cat.categoryId === active[1].category
        )
      : null;
  }
);

export const selectedSubLevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedTopLevelSelector],
  (active, topLevel) => {
    if (!topLevel) {
      return null;
    }

    return topLevel.childCategories.length
      ? topLevel.childCategories.find(
          cat => cat.categoryId === active[2].category
        )
      : null;
  }
);

export const selectedLeafLevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedSubLevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
          cat => cat.categoryId === active[3].category
        )
      : null;
  }
);

export const selectedLeaf2LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeafLevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[4].category
      )
      : null;
  }
);

export const selectedLeaf3LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeaf2LevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[5].category
      )
      : null;
  }
);
export const selectedLeaf4LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeaf3LevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[6].category
      )
      : null;
  }
);

export const selectedLeaf5LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeaf4LevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[7].category
      )
      : null;
  }
);

export const selectedLeaf6LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeaf5LevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[8].category
      )
      : null;
  }
);

export const selectedLeaf7LevelSelector: Selector<
  AppState,
  NavigationCategory | null
> = createSelector(
  [selectActiveNavItems, selectedLeaf6LevelSelector],
  (active, topsubLevel) => {
    if (!topsubLevel) {
      return null;
    }

    return topsubLevel.childCategories.length
      ? topsubLevel.childCategories.find(
        cat => cat.categoryId === active[9].category
      )
      : null;
  }
);


export const activeNavSelector: Selector<
  AppState,
  ActiveNav[]
> = createSelector(
  [selectActiveNavItems],
  active => active
);

export const loadingSelector: Selector<AppState, boolean> = createSelector(
  [selectLoading],
  isLoading => isLoading
);

export const errorSelector: Selector<AppState, null | string> = createSelector(
  [selectError],
  error => error
);

export const menuSelector: Selector<
  AppState,
  NavigationCategory[]
> = createSelector(
  [selectMenu],
  data => data
);

export const activeIndexReferenceSelector: Selector<
  AppState,
  number
> = createSelector(
  [selectActiveIndexReference],
  indexReference => indexReference
);

export const selectedTopLevelDomainSelector: Selector<
  AppState,
  {
    indexReference: number,
    domain: string,
    hasVRNSearch: boolean
  }
> = createSelector(
  [selectedPillarLevelSelector],
  pillarLevel => {
    if (!!pillarLevel) {
      const defaultDomain = pipe(
        toLower,
        trimSpace
      )(pillarLevel.nodeName);

      const [
        { indexReference, domain, hasVRNSearch } = {
          indexReference: NO_INDEX_REFERENCE,
          domain: defaultDomain,
          hasVRNSearch: false
        }
      ]: SearchDomain[] = searchDomain.filter(
        domain => toLower(domain.domain) === defaultDomain
      );

      return {
        indexReference: indexReference,
        domain: domain,
        hasVRNSearch: hasVRNSearch
      };
    }

    return null;
  }
);

export const isInBrowserSortFilterSelector: Selector<
  AppState,
  boolean
> = createSelector(
  [selectedPillarLevelSelector, selectActiveNavItems, selectMenu],
  (activePillar, activeNavigation, navigationTree) => {
    const selected = activeNavigation.filter(nav => !!nav.category);

    if (selected.length) {
      const lastNode = selected[selected.length - 1];

      if (!lastNode.isLeaf) {
        return false;
      }

      const getActiveLeaf = (navItem, targetId) => {
        if (navItem.categoryId === targetId) {
          return navItem;
        }
        if (navItem.childCategories) {
          for (let item of navItem.childCategories) {
            let check = getActiveLeaf(item, targetId);
            if (check) {
              return check;
            }
          }
        }
        return null;
      };

      const activeLeaf = getActiveLeaf(
        activePillar,
        selected[selected.length - 1].category
      );

      if (!!activeLeaf) {
        if (toLower(activeLeaf.vehicleLookup) === 'yes') {
          return true;
        } else if (
          MANUAL_CYCLE_LOOKUP_NODES.includes(toLower(activeLeaf.nodeName))
        ) {
          return true;
        }
      }
    }

    return false;
  }
);
