import type { Lookup, SearchDomain } from 'config/searchDomain/types';

import { MANUAL_LOOKUP_PATH } from 'routes/paths';

const lookup: Lookup = {
  title: 'Manual search by',
  label: 'Make & Model',
  isDisabled: false,
  pathname: MANUAL_LOOKUP_PATH
};

export const MOTORING_DOMAIN = 'motoring';

export const MOTORCYCLES_DOMAIN = 'motorcycles';

export const CYCLING_DOMAIN = 'cycling';

export const MANUAL_CYCLE_LOOKUP_NODES = ['spares by bike spec'];

export const NO_INDEX_REFERENCE = -1;

export const MOTORING_INDEX_REFERENCE = 6;

export const MOTORCYCLES_INDEX_REFERENCE = 7;

export const CYCLING_INDEX_REFERENCE = 8;

export const motoringDomain: SearchDomain = {
  domain: MOTORING_DOMAIN,
  indexReference: MOTORING_INDEX_REFERENCE,
  hasVRNSearch: true,
  title: 'Motoring Lookup',
  lookup
};

export const motorcycleDomain: SearchDomain = {
  domain: MOTORCYCLES_DOMAIN,
  indexReference: MOTORCYCLES_INDEX_REFERENCE,
  hasVRNSearch: true,
  title: 'Motorcycles Lookup',
  lookup
};

export const cycleDomain: SearchDomain = {
  domain: CYCLING_DOMAIN,
  indexReference: CYCLING_INDEX_REFERENCE,
  hasVRNSearch: false,
  title: 'Spares By Bike Spec',
  lookup
};

export default [cycleDomain, motoringDomain, motorcycleDomain];
