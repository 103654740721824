import type { NavigationCategory, FetchError } from 'types';
import type { FetchActionInit, FetchActionResponse } from 'actions/types';

import { fetchAppNavigation } from 'app/sidebar/api';
import {
  SET_ACTIVE_NAVIGATION,
  UNSET_NAVIGATION,
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_NAVIGATION_FAIL
} from 'actions/navigation/actionTypes';
import type {
  FetchNavigationType,
  NavigationSetterPayload,
  SetNavigationType,
  UnSetNavigationType,
  FetchNavigationSuccessType,
  FetchNavigationFailType,
  FetchSidebarNavigationAction
} from 'actions/navigation/types';

const fetchNavigation: FetchActionInit<FetchNavigationType> = () => ({
  type: FETCH_NAVIGATION
});

const setActiveNavigation: FetchActionResponse<
  NavigationSetterPayload,
  SetNavigationType
> = payload => ({
  type: SET_ACTIVE_NAVIGATION,
  payload
});

const unsetSelectedNavigation: FetchActionInit<UnSetNavigationType> = () => ({
  type: UNSET_NAVIGATION
});

const fetchNavigationSuccess: FetchActionResponse<
  { data: NavigationCategory[] },
  FetchNavigationSuccessType
> = payload => ({
  type: FETCH_NAVIGATION_SUCCESS,
  payload
});

const fetchNavigationFail: FetchActionResponse<
  FetchError,
  FetchNavigationFailType
> = payload => ({
  type: FETCH_NAVIGATION_FAIL,
  payload
});

const fetchSidebarNavigation: FetchSidebarNavigationAction = () => async dispatch => {
  dispatch(fetchNavigation());
  try {
    const response = await fetchAppNavigation();

    dispatch(
      fetchNavigationSuccess({ data: response.topCategories.categoryStructure })
    );
  } catch (error) {
    dispatch(fetchNavigationFail({ error: error.message }));
  }
};

export {
  setActiveNavigation,
  unsetSelectedNavigation,
  fetchNavigation,
  fetchNavigationSuccess,
  fetchNavigationFail,
  fetchSidebarNavigation
};
