import type {
  AppState,
  Product,
  ProductBundle,
  GenericNote,
  Pagination,
  BulbFitment,
  FittingInstructions
} from 'types';
import type { Selector } from 'selectors/types';

import { createSelector } from 'reselect';
import { get, isNil, isEmpty } from 'lodash/fp';
import { UNSPECIFIED_FITMENT_LOCATION } from 'libs/constants';

const selectProducts = state => state.products.data;
const selectProductsSort = state => state.products.sort;
const selectVendors = state => state.products.vendors;
const selectBrands = state => state.products.brandNames;
const selectBundles = state => state.products.bundles;
const selectProductsError = state => state.products.error;
const selectProductsLoading = state => state.products.isLoading;
const selectFilter = state => state.products.filter;
const selectPagination = state => state.products.pagination;
const selectNotes = state => state.products.notes;
const selectBulbFitment = state => state.products.bulbFitment;
const selectProducTypeNames = state => state.products.typeNames;
const selectFittingInstructions = state => state.products.fittingInstructions;

export const productLoadingSelector: Selector<
  AppState,
  boolean
> = createSelector(
  selectProductsLoading,
  isLoading => isLoading
);

export const productErrorSelector: Selector<
  AppState,
  null | string
> = createSelector(
  selectProductsError,
  error => error
);

export const sortOptionSelector: Selector<AppState, string> = createSelector(
  selectProductsSort,
  option => option
);

export const brandsSelector: Selector<AppState, string[]> = createSelector(
  selectBrands,
  brands => brands
);

export const bundlesSelector: Selector<
  AppState,
  ProductBundle[]
> = createSelector(
  selectBundles,
  bundle => bundle
);

export const productTypeNamesSelector: Selector<
  AppState,
  string[]
> = createSelector(
  selectProducTypeNames,
  types => types
);

export const genericNotesSelector: Selector<
  AppState,
  GenericNote[]
> = createSelector(
  selectNotes,
  notes => notes
);

export const vendorsSelector: Selector<AppState, string[]> = createSelector(
  selectVendors,
  vendors => vendors
);

export const filterSelector: Selector<
  AppState,
  { name: string, variant: number[] }
> = createSelector(
  selectFilter,
  filter => filter
);

export const productsSelector: Selector<AppState, Product[]> = createSelector(
  [selectProducts],
  products => products
);

export const paginationSelector: Selector<
  AppState,
  Pagination
> = createSelector(
  selectPagination,
  pagination => pagination
);

export const fittingInstructionsSelector: Selector<
  AppState,
  FittingInstructions
> = createSelector(
  selectFittingInstructions,
  fittingInstructions => fittingInstructions
);

export const bulbFitmentSelector: Selector<
  AppState,
  BulbFitment[]
> = createSelector(
  selectBulbFitment,
  fitment => fitment
);

export const bulbFitmentTreeSelector: Selector<
  AppState,
  { [key: string]: string[] }
> = createSelector(
  selectBulbFitment,
  fitment => {
    const positionLocations = [
      ...new Set(
        fitment.length
          ? fitment
              .map(fit => get(`criteria.${'fitting Position'}`, fit))
              .filter(location => !!location)
          : []
      )
    ];

    const fitmentByUnkownLocation = fitment
      .filter(fit => !get(`criteria.${'fitting Position'}`, fit))
      .map(fit => get(`criteria.${'bulb Fitment'}`, fit));

    const fitmentByLocation = positionLocations.map(location => {
      return {
        [location]: [
          ...new Set(
            fitment
              .filter(
                fit => get(`criteria.${'fitting Position'}`, fit) === location
              )
              .map(locationFitment =>
                {
                  return !!get(`criteria.${'bulb Fitment'}`, locationFitment)?
                  get(`criteria.${'bulb Fitment'}`, locationFitment):UNSPECIFIED_FITMENT_LOCATION;
                }
              )
          )
        ]
      };
    });

    return Object.assign(
      {},
      ...fitmentByLocation,
      fitmentByUnkownLocation.length
        ? { [UNSPECIFIED_FITMENT_LOCATION]: fitmentByUnkownLocation }
        : {}
    );
  }
);

export const bulbFitmetSelectionSelector: Selector<
  AppState,
  { [key: string]: string }[]
> = createSelector(
  [selectFilter, bulbFitmentTreeSelector, bulbFitmentSelector],
  (activeFilters, bulbFitmentTree, bulbFitment) => {
    if (activeFilters.name === 'fitmentLocation') {
      const [locationIndex, fitmentIndex] = activeFilters.variant;

      if (
        !isNil(locationIndex) &&
        !isNil(fitmentIndex) &&
        !isEmpty(bulbFitment)
      ) {
        const activeLocation = Object.keys(bulbFitmentTree)[locationIndex];

        const activeFitmentType = bulbFitmentTree[activeLocation][fitmentIndex];

        return bulbFitment.filter(fit => {
          const fitType = get(`criteria.${'bulb Fitment'}`, fit);
          const fitLocation = get(`criteria.${'fitting Position'}`, fit);
          if (activeLocation === UNSPECIFIED_FITMENT_LOCATION) {
            return fitType === activeFitmentType && !fitLocation;
          }

          return (
            fitType === activeFitmentType && fitLocation === activeLocation
          );
        });
      }
    }
    return bulbFitment;
  }
);
