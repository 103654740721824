import './styles.scss';
import type { Props } from 'components/Breadcrumbs/types';

import * as React from 'react';
import uuid from 'uuid/v4';
import classNames from 'classnames';
import { Link } from '@reach/router';
import { Typography, Paper, Breadcrumbs } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import NextIcon from '@material-ui/icons/ChevronRight';

const BreadCrumbs = ({ data }: Props) => {
  return (
    <Paper elevation={0} className="crumb__paper">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NextIcon fontSize="small" />}>
        {data.map(({ path, onClick, label, isHome }, i) => {
          if (!!onClick || !!path) {
            return (
              <Link
                key={uuid()}
                to={path}
                onClick={onClick}
                className={classNames('breadcrumb', {
                  'breadcrumb--disabled': i === data.length - 1
                })}>
                {isHome && <HomeIcon className="icon" fontSize="large" />}
                <Typography variant="body1">{label}</Typography>
              </Link>
            );
          }

          return (
            <Typography variant="body1" key={uuid()}>
              {label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
};

export default BreadCrumbs;
