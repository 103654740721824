import './styles.scss';
import type { Props } from 'components/Header/types';

import * as React from 'react';
import { toLower, pipe } from 'lodash/fp';
import { AppBar, Toolbar, IconButton, Box } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/PauseCircleOutlineSharp';
import PlayIcon from '@material-ui/icons/PlayCircleOutlineSharp';
import { CYCLE_REPUBLIC_LOGO, CYCLE_REPUBLIC } from 'libs/constants';
import trimSpace from 'libs/trimSpace';
import HALFORDS_LOGO from 'assets/img/halfords-logo.svg';
import useTill from 'hooks/useTill';

import Searchbar from 'app/search';
const { Fragment } = React;

const Header: React.StatelessFunctionalComponent<Props> = ({
  noSession,
  hasPaused,
  pausedSession,
  resumedSession,
  pendingResume,
  activeSession,
  onPauseSession,
  onResumeSession,
  onLogoClick,
  auth
}) => {
  const { loggedIn, till } = useTill();

  const isCycleRepublic =
    loggedIn &&
    pipe(
      trimSpace,
      toLower
    )(till.store_type) === toLower(CYCLE_REPUBLIC);

  return (
    <AppBar position="relative" elevation={0} classes={{ root: 'app-bar' }}>
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          flexDirection="row">
          <Box>
            {auth.loggedIn && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                data-testid="header-logo-btn"
                onClick={onLogoClick}
                className="header-logo">
                <img
                  data-testid="logo"
                  src={isCycleRepublic ? CYCLE_REPUBLIC_LOGO : HALFORDS_LOGO}
                  className="logo"
                  alt="Partsfinder logo"
                />
              </IconButton>
            )}
            {auth.loggedIn && (
              <Fragment>
                <IconButton
                  color="inherit"
                  style={{ flexGrow: 1 }}
                  disabled={
                    !hasPaused || noSession || pausedSession || pendingResume
                  }
                  classes={{
                    disabled: 'icon-button-disabled'
                  }}
                  onClick={onResumeSession}>
                  <PlayIcon fontSize="large" /> Resume session
                </IconButton>

                <IconButton
                  color="inherit"
                  disabled={!activeSession || noSession || pendingResume}
                  style={{ flexGrow: 1 }}
                  classes={{
                    disabled: 'icon-button-disabled'
                  }}
                  onClick={onPauseSession}>
                  <PauseIcon fontSize="large" /> Pause session
                </IconButton>
              </Fragment>
            )}
          </Box>
          {auth.loggedIn && activeSession && (
            <Box justifyContent="flex-end" width="340px">
              <Searchbar />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
