import type { BarcodeLookupState } from 'types';
import type { Reducer } from 'reducers/types';
import type {
  ResetBarcode,
  FetchBarcode,
  FetchBarcodeSuccess,
  FetchBarcodeFail
} from 'actions/barcodeLookup/types';

import {
  FETCH_BARCODE_PRODUCT,
  FETCH_BARCODE_PRODUCT_SUCCESS,
  FETCH_BARCODE_PRODUCT_FAIL,
  RESET_BARCODE_PRODUCT
} from 'actions/barcodeLookup/actionTypes';
import { initState } from 'reducers';

type ActionType =
  | ResetBarcode
  | FetchBarcode
  | FetchBarcodeSuccess
  | FetchBarcodeFail;

const barcodeLookupReducer: Reducer<
  BarcodeLookupState,
  {
    type: ActionType,
    payload: {
      data?: any,
      barcode?: string,
      vendors?: string[],
      error?: string
    }
  }
> = (state = initState.barcodeLookup, { type, payload }) => {
  switch (type) {
    case RESET_BARCODE_PRODUCT:
      return {
        ...initState.barcodeLookup
      };
    case FETCH_BARCODE_PRODUCT:
      return {
        ...initState.barcodeLookup,
        barcode: payload.barcode,
        isLoading: true
      };
    case FETCH_BARCODE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: payload.data,
        vendors: payload.vendors,
        fetchComplete: true,
        redirectPermitted: true,
        error: null,
        isLoading: false
      };
    case FETCH_BARCODE_PRODUCT_FAIL:
      return {
        ...state,
        data: [],
        vendors: [],
        fetchComplete: true,
        error: payload.error,
        redirectPermitted: false,
        isLoading: false
      };
    default:
      return state;
  }
};

export default barcodeLookupReducer;
