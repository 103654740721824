import type {
  ResetManualVehicleLookupType,
  FetchManualVehicleLookupType,
  FetchManualVehicleLookupSuccessType,
  FetchManualVehicleLookupFailType,
  SetSelectedFromLookupVehiclesType,
  UnsetManualVehicleLookupRedirectType
} from 'actions/manualVehicleLookup/types';

export const RESET_MANUAL_VECHICLE_LOOKUP: ResetManualVehicleLookupType =
  '@@vehicle/manaul-lookup/RESET_MANUAL_VECHICLE_LOOKUP';
export const FETCH_MANUAL_VECHICLE_LOOKUP: FetchManualVehicleLookupType =
  '@@vehicle/manaul-lookup/FETCH_MANUAL_VECHICLE_LOOKUP';
export const FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS: FetchManualVehicleLookupSuccessType =
  '@@vehicle/manaul-lookup/FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS';
export const FETCH_MANUAL_VECHICLE_LOOKUP_FAIL: FetchManualVehicleLookupFailType =
  '@@vehicle/manaul-lookup/FETCH_MANUAL_VECHICLE_LOOKUP_FAIL';
export const UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT: UnsetManualVehicleLookupRedirectType =
  '@@vehicle/manaul-lookup/UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT';
export const SET_MANUAL_VECHICLE_LOOKUP_SELECTED: SetSelectedFromLookupVehiclesType =
  '@@vehicle/manaul-lookup/SET_MANUAL_VECHICLE_LOOKUP_SELECTED';
