import type { AppState, ObjectShape } from 'types';
import type { Reducer, RootReducer } from 'reducers/types';

import { isEmpty } from 'lodash/fp';
import { combineReducers } from 'redux';
import breadcrumbs from 'reducers/breadcrumbs';
import products from 'reducers/products';
import search from 'reducers/search';
import barcodeLookup from 'reducers/barcodeLookup';
import navigation, { initState as initSidebarState } from 'reducers/navigation';
import searchDomain from 'reducers/searchDomain';
import session, { initState as initSessionState } from 'reducers/session';
import vehicleRegNumber from 'reducers/vehicleRegNumber';
import manualVehicleLookup from 'reducers/manualVehicleLookup';
import vendors from 'reducers/vendors';
import promoted from 'reducers/promoted';
import filterSettings from 'reducers/filterSettings';
import sidebar from 'reducers/sidebar';
import vehicleChangePreviousLocation from 'reducers/vehicleChangePreviousLocation';
import { NO_INDEX_REFERENCE } from 'config/searchDomain';
import { PAGE_SIZE } from 'libs/constants';

import { loadSession } from 'hooks/useSession';
import {
  SESSION_START,
  SESSION_START_WITH_HISTORY,
  SESSION_RESUME,
  SESSION_END,
  SESSION_CLEAR
} from 'actions/session/actionTypes';

const {
  active: { location, ...sessionState }
} = loadSession();

const appInitState: AppState = {
  session: initSessionState,
  navigation: initSidebarState,
  sidebar: {
    isOpen: true
  },
  searchDomain: {
    domain: '',
    indexReference: NO_INDEX_REFERENCE,
    autoSelectCategory: false
  },
  breadcrumbs: {
    data: []
  },
  search: {
    active: {
      term: '',
      selection: -1
    },
    data: [],
    vendors: [],
    brandNames: [],
    error: null,
    isLoading: false,
    isLoadingFilters: false,
    pagination: {
      total: 0,
      skipped: 0,
      current: 0,
      pageTotal: PAGE_SIZE
    },
    sort: '',
    filter: {
      vendor: '',
      brandName: ''
    },
    redirectPermitted: false
  },
  products: {
    data: [],
    bundles: [],
    vendors: [],
    brandNames: [],
    bulbFitment: [],
    notes: [],
    typeNames: [],
    fittingInstructions: {},
    pagination: {
      total: 0,
      skipped: 0,
      current: 0,
      pageTotal: PAGE_SIZE
    },
    sort: '',
    filter: {
      brandName: '',
      vendor: '',
      fitmentLocation: '',
      fitmentPart: '',
      productTypeName: ''
    },
    error: null,
    isLoading: false
  },
  vehicleRegNumber: {
    data: {},
    fetchComplete: false,
    error: null,
    isLoading: false,
    redirectPermitted: true
  },
  manualVehicleLookup: {
    data: {},
    selected: -1,
    fetchComplete: false,
    error: null,
    isLoading: false,
    redirectPermitted: false
  },
  barcodeLookup: {
    barcode: '',
    data: [],
    vendors: [],
    fetchComplete: false,
    error: null,
    isLoading: false,
    redirectPermitted: false
  },
  promoted: {
    data: {}
  },
  vendors: {
    isLoading: false,
    fetchComplete: false,
    data: [],
    error: ''
  },
  vehicleChangePreviousLocation: {
    location: []
  },
  filterSettings: {
    isPresetAllowed: true
  }
};

const initState = isEmpty(sessionState) ? appInitState : sessionState;

const allReducers: Reducer<
  AppState,
  { type: string, payload?: ObjectShape }
> = combineReducers({
  search,
  sidebar,
  session,
  vendors,
  products,
  promoted,
  navigation,
  breadcrumbs,
  searchDomain,
  barcodeLookup,
  filterSettings,
  vehicleRegNumber,
  manualVehicleLookup,
  vehicleChangePreviousLocation
});

const rootReducer: RootReducer = (state, action) => {
  const { navigation } = state;
  const navState = {
    navigation: {
      ...appInitState.navigation,
      data: navigation.data
    }
  };

  switch (action.type) {
    case SESSION_START_WITH_HISTORY:
      state = {
        ...appInitState,
        ...navState,
        vehicleChangePreviousLocation: {
          ...state.vehicleChangePreviousLocation
        }
      };
      break;

    case SESSION_START:
      state = {
        ...appInitState,
        ...navState
      };
      break;

    case SESSION_END:
    case SESSION_CLEAR:
      state = {
        ...appInitState,
        ...navState
      };
      break;

    case SESSION_RESUME:
      const { location, ...restOfState } = action.payload.state;
      state = {
        ...restOfState
      };
      break;

    default:
      break;
  }

  return allReducers(state, action);
};

export { rootReducer as default, initState };
