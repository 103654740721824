import type {
  SetSearchDomainAction,
  SetNavSearchDomainAction,
  UnsetSearchDomainAction
} from 'actions/searchDomain/types';

import { toLower, pipe } from 'lodash/fp';
import {
  SET_SEARCH_DOMAIN,
  UNSET_SEARCH_DOMAIN,
  SET_NAV_SEARCH_DOMAIN
} from 'actions/searchDomain/actionTypes';
import domains, { NO_INDEX_REFERENCE } from 'config/searchDomain';
import trimSpace from 'libs/trimSpace';

export const unsetSearchDomain: UnsetSearchDomainAction = () => ({
  type: UNSET_SEARCH_DOMAIN
});

export const setSearchDomainReference: SetSearchDomainAction = (
  indexReference,
  autoSelectCategory = false
) => {
  const [{ domain }] = domains.filter(
    domain => domain.indexReference === indexReference
  );

  return {
    type: SET_SEARCH_DOMAIN,
    payload: {
      indexReference,
      domain,
      autoSelectCategory
    }
  };
};

export const setSearchDomainReferenceFromNav: SetNavSearchDomainAction = (
  domainname,
  autoSelectCategory = false,
  clearVehicleData = false
) => {
  const defaultDomain = pipe(
    toLower,
    trimSpace
  )(domainname);
  const [
    { domain, indexReference } = {
      domain: defaultDomain,
      indexReference: NO_INDEX_REFERENCE
    }
  ] = domains.filter(domain => toLower(domain.domain) === defaultDomain);

  return {
    type: SET_NAV_SEARCH_DOMAIN,
    payload: {
      indexReference,
      domain,
      autoSelectCategory,
      clearVehicleData
    }
  };
};
