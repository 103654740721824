import type { Reducer } from 'reducers/types';
import type { SidebarState } from 'types';
import type { ToggleSidebarActionType } from 'actions/sidebar/types';

import { TOGGLE_SIDEBAR } from 'actions/sidebar/actionTypes';
import { initState } from 'reducers';

const sidebarReducer: Reducer<
  SidebarState,
  {
    type: ToggleSidebarActionType
  }
> = (state = initState.sidebar, { type }) => {
  switch (type) {
    case TOGGLE_SIDEBAR:
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
};

export default sidebarReducer;
