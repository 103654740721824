import type {
  FetchError,
  Product,
  GenericNote,
  FittingInstructions
} from 'types';
import type { FetchActionInit, FetchActionResponse } from 'actions/types';
import type {
  ClearProductsSortFilterType,
  ClearProductsPaginationType,
  ClearProductsFilterType,
  ResetProductsType,
  InitFetchProducts,
  FetchProductsSuccessPayload,
  FetchProductsSuccessType,
  FetchProductsFailType,
  SetProductsSortType,
  ProductFilterPayload,
  SetProductsFilterType,
  FetchProductListAction,
  FetchVehicleProductListAction
} from 'actions/products/types';
import type { BaseNavVehicleFetchResponse } from 'api/products/types';

import { pipe, get, isEmpty } from 'lodash/fp';
import {
  fetchProducts as productFetch,
  fetchVehicleProducts as vehicleProductFetch
} from 'api/products';
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  SET_PRODUCTS_SORT,
  SET_PRODUCTS_FILTER,
  RESET_PRODUCTS,
  CLEAR_PRODUCTS_SORT_FILTER,
  CLEAR_PRODUCT_FILTER,
  CLEAR_PRODUCT_PAGINATION
} from 'actions/products/actionTypes';

const resetProducts: FetchActionInit<ResetProductsType> = () => ({
  type: RESET_PRODUCTS
});

const clearProductPagination: FetchActionInit<ClearProductsPaginationType> = () => ({
  type: CLEAR_PRODUCT_PAGINATION
});

const clearProductSortFilters: FetchActionInit<ClearProductsSortFilterType> = () => ({
  type: CLEAR_PRODUCTS_SORT_FILTER
});

const clearProductFilters: FetchActionInit<ClearProductsFilterType> = () => ({
  type: CLEAR_PRODUCT_FILTER
});

const fetchProducts: InitFetchProducts = payload => ({
  type: FETCH_PRODUCTS,
  payload
});

const fetchProductsSuccess: FetchActionResponse<
  FetchProductsSuccessPayload,
  FetchProductsSuccessType
> = payload => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload
});

const fetchProductsFail: FetchActionResponse<
  FetchError,
  FetchProductsFailType
> = payload => ({
  type: FETCH_PRODUCTS_FAIL,
  payload
});

export const setProductsSort: FetchActionResponse<
  { sort: string },
  SetProductsSortType
> = payload => ({
  type: SET_PRODUCTS_SORT,
  payload
});

export const setProductsFilter: FetchActionResponse<
  ProductFilterPayload,
  SetProductsFilterType
> = payload => ({
  type: SET_PRODUCTS_FILTER,
  payload
});

const processResult = (
  result: BaseNavVehicleFetchResponse
): {
  products: Product[],
  vendors: string[],
  brands: string[],
  bundles: Object[],
  bulbFitment: Object[],
  pagination?: Object,
  fittingInstructions: {} | FittingInstructions,
  notes: GenericNote[] | []
} => {
  const productData =
    result.baseResponse.resultText === 'success'
      ? result
      : {
          products: [],
          sapVendors: [],
          brands: [],
          applications: [],
          productTypeNames: []
        };

  const applications = get('applications', productData);
  const fittingData = get('fittingData', productData);

  let fittingInstructions = {};

  if (!isEmpty(fittingData)) {
    const instructions = !!get('topicInformation.technicalData', fittingData)
      ? get('topicInformation.technicalData', fittingData)
      : get('fuseInformation.technicalData', fittingData);

    if (!isEmpty(instructions)) {
      fittingInstructions = {
        header: get('vehicleData.content[0]', fittingData),
        instructions
      };
    }
  }

  const hasApplicationData = !isEmpty(applications);

  const hasFitments =
    hasApplicationData &&
    (!isEmpty(
      applications.find(app => !!get('criteria["bulb Fitment"]', app))
    ) ||
      !isEmpty(
        applications.find(app => !!get('criteria["fitting Position"]', app))
      ));

  const applicationNotes = hasApplicationData
    ? applications.filter(app => !!get('notes', app))
    : [];

  const notes = applicationNotes.map(({ notes, productReference }) => ({
    notes,
    productReference
  }));

  const { total, skipped, size, page } = result;

  let payload = {
    products: productData.products,
    vendors: productData.sapVendors,
    brands: productData.brands,
    fittingInstructions,
    bundles: !isEmpty(applications)
      ? productData.applications.filter(data => !!get('criteria.Bundle', data))
      : [],
    bulbFitment: hasFitments
      ? productData.applications.filter(
          data =>
            !!get('criteria["bulb Fitment"]', data) ||
            !!get('criteria["fitting Position"]', data)
        )
      : [],
    typeNames: productData.productTypeNames,
    notes
  };

  if (!isNaN(page) && !isNaN(size)) {
    payload = {
      ...payload,
      total,
      skipped,
      pageTotal: size,
      current: page
    };
  }

  return payload;
};

const fetchProductsList: FetchProductListAction = ({
  resetFilters = true,
  ...args
}) => async dispatch => {
  dispatch(fetchProducts({ resetFilters }));

  try {
    const result = await productFetch(args);
    pipe(
      processResult,
      fetchProductsSuccess,
      dispatch
    )({ ...result, ...args });
  } catch (error) {
    dispatch(fetchProductsFail({ error: error.message }));
  }
};

const fetchVehicleProductsList: FetchVehicleProductListAction = args => async dispatch => {
  dispatch(fetchProducts({ resetFilters: true }));

  try {
    const result = await vehicleProductFetch(args);
    pipe(
      processResult,
      fetchProductsSuccess,
      dispatch
    )(result);
  } catch (error) {
    dispatch(fetchProductsFail({ error: error.message }));
  }
};

export {
  fetchProductsList,
  fetchVehicleProductsList,
  resetProducts,
  clearProductSortFilters,
  clearProductFilters,
  clearProductPagination
};
