import type {
  StartSession,
  StartSessionWithHistory,
  EndSession,
  PauseSession,
  PauseResumeSession,
  ClearSession,
  ResumeSession
} from 'actions/session/types';

import {
  SESSION_PAUSE,
  SESSION_RESUME,
  SESSION_START,
  SESSION_START_WITH_HISTORY,
  SESSION_CLEAR,
  SESSION_END,
  SESSION_PENDING_RESUME
} from 'actions/session/actionTypes';
import { loadSession } from 'hooks/useSession';

export const startSession: StartSession = () => ({
  type: SESSION_START
});

export const startSessionWithHistory: StartSessionWithHistory = () => ({
  type: SESSION_START_WITH_HISTORY
});

export const endSession: EndSession = () => ({
  type: SESSION_END
});

export const pauseSession: PauseSession = () => ({ type: SESSION_PAUSE });

export const pendingResumeSession: PauseResumeSession = () => ({
  type: SESSION_PENDING_RESUME
});

export const resumeSession: ResumeSession = () => {
  const { paused } = loadSession();

  return {
    type: SESSION_RESUME,
    payload: { state: paused }
  };
};

export const clearSessions: ClearSession = () => {
  return { type: SESSION_CLEAR };
};
