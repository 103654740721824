import type { SearchFieldsType } from 'app/search/types';

export const searchFields: SearchFieldsType = [
  {
    name: -1,
    label: 'Wild card'
  },
  {
    name: 'partDescription',
    label: 'Description'
  },
  {
    name: 'partNumber',
    label: 'Part number'
  },
  {
    name: 'sapID',
    label: 'Article ID'
  },
  {
    name: 'barcode',
    label: 'Barcode'
  },
  {
    name: 'vendor',
    label: 'Vendor name'
  },
  {
    name: 'partNumbers',
    label: 'Hot Part'
  }
];
