export const FETCH_VEHICLE_REG_NUMBER =
  '@@vehicle/vrn/FETCH_VEHICLE_REG_NUMBER';
export const FETCH_VEHICLE_REG_NUMBER_SUCCESS =
  '@@vehicle/vrn/FETCH_VEHICLE_REG_NUMBER_SUCCESS';
export const FETCH_VEHICLE_REG_NUMBER_FAIL =
  '@@vehicle/vrn/FETCH_VEHICLE_REG_NUMBER_FAIL';
export const UNSET_VEHICLE_REG_NUMBER_REDIRECT =
  '@@vehicle/vrn/UNSET_VEHICLE_REG_NUMBER_REDIRECT';
export const RESET_VEHICLE_REG_NUMBER =
  '@@vehicle/vrn/RESET_VEHICLE_REG_NUMBER';
