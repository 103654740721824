import type { ObjectShape, ProductSearchArgs } from 'types';
import type {
  BasePrintProps,
  BaseNavVehicleFetchResponse,
  BaseSearchFetchResponse
} from 'api/products/types';

import type {
  FetchProductFetchArgs,
  FetchVehicleProductFetchArgs
} from 'actions/products/types';

import { executeFetch } from 'api';

export const fetchProducts: Function = ({
  categoryId,
  ...args
}: FetchProductFetchArgs): Promise<BaseSearchFetchResponse> =>
  executeFetch(`products/externalNode/${categoryId}`, args);

export const fetchVehicleProducts = ({
  internalReference,
  categoryId,
  ...args
}: FetchVehicleProductFetchArgs): Promise<BaseNavVehicleFetchResponse> =>
  executeFetch(`applications/intref/${internalReference}`, {
    externalNode: categoryId,
    ...args
  });

export const fetchNearestStoreStock = (args: {
  hssIds: string,
  articleNumber: string
}): Promise<any> => executeFetch('article/nearestStoreStock', args);

export const fetchProductsByReference = (args: {
  prefs: number | number[],
  storeIds: string
}): Promise<any> => executeFetch('products/productreferences', args);

export const fetchStoreVendor = (storeId: string): Promise<any> =>
  executeFetch(`vendorDepot/byStoreId/${storeId}`);

export const fetchFittingPrintData = ({
  lookupType,
  lookupValue,
  topic,
  ...args
}: BasePrintProps & ObjectShape): Promise<any> =>
  executeFetch(
    `fitting/v2/lookuptype/${lookupType}/lookupvalue/${lookupValue}/topic/${topic}`,
    args
  );

export const fetchProductsSearch = ({
  search,
  ...args
}: ProductSearchArgs): Promise<BaseSearchFetchResponse> =>
  executeFetch('products/searchProducts', args);

export const fetchWildSearch = ({
  search,
  ...args
}: ProductSearchArgs): Promise<BaseSearchFetchResponse> =>
  executeFetch('products/searchWild', args);

export const fetchPartNumbersSearch = ({
  search,
  ...args
}: ProductSearchArgs): Promise<BaseSearchFetchResponse> =>
  executeFetch('products/partnumbers', args);
