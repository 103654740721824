import type { Reducer } from 'reducers/types';
import type { SessionState } from 'types';
import type {
  SessionPauseType,
  SessionResumeType,
  SessionStartType,
  SessionPendingResumeType
} from 'actions/session/types';

import {
  SESSION_PAUSE,
  SESSION_RESUME,
  SESSION_START,
  SESSION_START_WITH_HISTORY,
  SESSION_PENDING_RESUME
} from 'actions/session/actionTypes';

type ActionType =
  | SessionStartType
  | SessionPauseType
  | SessionResumeType
  | SessionPendingResumeType;

export const initState: SessionState = {
  newSession: false,
  pausedSession: false,
  resumedSession: false,
  pendingResumeSession: false
};

const sessionReducer: Reducer<SessionState, { type: ActionType }> = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case SESSION_START:
    case SESSION_START_WITH_HISTORY:
      return {
        ...state,
        newSession: true
      };

    case SESSION_PAUSE:
      return {
        ...initState,
        pausedSession: true
      };

    case SESSION_RESUME:
      return {
        ...state,
        resumedSession: true
      };

    case SESSION_PENDING_RESUME:
      return {
        ...state,
        pendingResumeSession: true
      };

    default:
      return state;
  }
};

export default sessionReducer;
