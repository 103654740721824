import type { AppState, ManualData } from 'types';
import type { Selector } from 'selectors/types';

import { createSelector } from 'reselect';

const selectLoading = state => state.manualVehicleLookup.isLoading;
const selectLFetchComplete = state => state.manualVehicleLookup.fetchComplete;
const selectError = state => state.manualVehicleLookup.error;
const selectManualVehicleData = state => state.manualVehicleLookup.data;
const selectSelectedVehicleData = state => state.manualVehicleLookup.selected;
const selectManualVehicleRedirect = state =>
  state.manualVehicleLookup.redirectPermitted;

export const loadingSelector: Selector<AppState, boolean> = createSelector(
  selectLoading,
  isLoading => isLoading
);

export const fetchCompleteSelector: Selector<
  AppState,
  boolean
> = createSelector(
  selectLFetchComplete,
  fetchComplete => fetchComplete
);

export const errorSelector: Selector<AppState, null | string> = createSelector(
  selectError,
  error => error
);

export const manualVehicleSelector: Selector<
  AppState,
  ManualData[]
> = createSelector(
  selectManualVehicleData,
  data => data
);

/**
 * Return selected vehicle index
 * @return {Number} - -1 if not selected or bigger than 0 if selected
 */
export const selectedVehicleIndex: Selector<AppState, number> = createSelector(
  selectSelectedVehicleData,
  selected => selected
);

/**
 * Return selected vehicle info
 */
export const selectedVehicleSelector: Selector<
  AppState,
  ManualData
> = createSelector(
  [selectManualVehicleData, selectSelectedVehicleData],
  (data, index) => (index > -1 ? data[index] : {})
);

export const redirectPermittedSelector: Selector<
  AppState,
  boolean
> = createSelector(
  selectManualVehicleRedirect,
  isRedirectPermitted => isRedirectPermitted
);
