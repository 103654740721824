import type { Store } from 'redux';
import type { AppState } from 'types';

import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import stateToSessionMiddleware from 'middlewares/stateToSession';
import clearVehicleDataOnNavPillarChange from 'middlewares/clearVehicleDataOnNavPillarChange';
import clearSortFiltersOnNavChange from 'middlewares/clearSortFiltersOnNavChange';
import clearSearchOrProductsOnFetch from 'middlewares/clearSearchOrProductsOnFetch';
import clearPaginationOnNavChange from 'middlewares/clearPaginationOnNavChange';
import replaceCurrentWithPreviousNavigation from 'middlewares/replaceCurrentWithPreviousNavigation';
import configureFilterSettings from 'middlewares/filterSettings';
import rootReducer from 'reducers';

interface Action {
  type: string;
  payload?: any;
}

function configureStore(initState: AppState): Store<AppState, Action> {
  const middlewares = [
    thunkMiddleware,
    configureFilterSettings,
    replaceCurrentWithPreviousNavigation,
    stateToSessionMiddleware,
    clearVehicleDataOnNavPillarChange,
    clearSortFiltersOnNavChange,
    clearSearchOrProductsOnFetch,
    clearPaginationOnNavChange
  ];
  //$FlowFixMe
  const enhancers = applyMiddleware(...middlewares);

  const composedEnhancers =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(enhancers)
      : enhancers;
  //$FlowFixMe
  return createStore(rootReducer, initState, composedEnhancers);
}

export default configureStore;
