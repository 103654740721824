import type {
  OnNavigationSelect,
  SelectionHandlerInterface
} from 'app/sidebar/types';

import { navigate } from '@reach/router';
//$FlowFixMe
import { resolve } from '@reach/router/lib/utils.js';

import { CATEGORY_LOOKUP_PATH } from 'routes/paths';

export const onNavigationSelect: OnNavigationSelect = handler => {
  return ({ depth, selection, isLeaf }) => {
    handler({
      depth,
      selection,
      isLeaf
    });
  };
};

export const selectionHandler: SelectionHandlerInterface = props => {
  const {
    onSelect,
    isPillarOpen,
    path,
    activePillar,
    setActivePillarDomain,
    open,
    ...args
  } = props;

  return event => {
    event.stopPropagation();
    onSelect(args);
    if (!open) {
      navigate(resolve(path, CATEGORY_LOOKUP_PATH), { replace: true });
    }
  };
};
