import {
  RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION,
  SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION
} from 'actions/vehicleChangePreviousLocation/actionTypes';
import {
  OnChangeVehicle,
  ResetVehicleChangePreviousLocation,
  SetVehicleChangePreviousLocation
} from 'actions/vehicleChangePreviousLocation/types';

const resetVehicleChangePreviousLocation: ResetVehicleChangePreviousLocation = () => ({
  type: RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION
});

const setVehicleChangePreviousLocation: SetVehicleChangePreviousLocation = payload => ({
  type: SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION,
  payload
});

const onChangeVehicle: OnChangeVehicle = () => (dispatch, getState) => {
  const { navigation } = getState();
  dispatch(setVehicleChangePreviousLocation({ location: navigation.active }));
};

export { onChangeVehicle as default, resetVehicleChangePreviousLocation };
