import type { Props } from 'routes/types';

import * as React from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';
import { sessionActionSelector } from 'selectors/session';
import {
  SESSION_PATH,
  ROOT_PATH,
  MANUAL_LOOKUP_PATH,
  VRM_LOOKUP_PATH,
  VEHICLE_PATH,
  CATEGORY_LOOKUP_PATH,
  BARCODE_LOOKUP_PATH,
  SEARCH_PRODUCTS_PATH
} from 'routes/paths';
import Spinner from 'components/Spinner';
import useTill from 'hooks/useTill';

const { lazy, Suspense } = React;

const Home = lazy(() => import('app/home'));
const SessionRouter = lazy(() => import('app/session/router'));
const BarcodeLookup = lazy(() => import('app/barcodeLookup'));
const BarcodeProducts = lazy(() => import('app/barcodeLookup/Products'));
const VehicleDetails = lazy(() => import('app/vehicleDetails'));
const SidebarSelection = lazy(() => import('app/sidebar/Selection'));
const Promotions = lazy(() => import('app/promotions'));
const TopLevelList = lazy(() => import('app/sidebar/TopLevelList'));
const TopLevelSubList = lazy(() => import('app/sidebar/TopLevelSubList'));
const TopLevelSubList2 = lazy(() => import('app/sidebar/TopLevelSubList2'));
const TopLevelSubList3 = lazy(() => import('app/sidebar/TopLevelSubList3'));
const TopLevelSubList4 = lazy(() => import('app/sidebar/TopLevelSubList4'));
const TopLevelSubList5 = lazy(() => import('app/sidebar/TopLevelSubList5'));
const TopLevelSubList6 = lazy(() => import('app/sidebar/TopLevelSubList6'));
const TopLevelSubList7 = lazy(() => import('app/sidebar/TopLevelSubList7'));

const Products = lazy(() => import('app/products'));
const NotFound = lazy(() => import('app/notFound'));
const ManualLookup = lazy(() => import('app/manualLookup'));
const SearchProducts = lazy(() => import('app/search/Products'));

const AppRouter: React.StatelessFunctionalComponent<Props> = props => {
  const { loggedIn } = useTill();
  const {
    newSession,
    pausedSession,
    resumedSession,
    pendingResumeSession: sessionResumePending
  } = useSelector(sessionActionSelector);

  const noSession =
    !newSession && !pausedSession && !resumedSession && !sessionResumePending;

  return (
    <Suspense delayMs={50} fallback={<Spinner visible />}>
      {!loggedIn || noSession ? (
        <Router>
          <SessionRouter default />
        </Router>
      ) : (
        <Router>
          <NotFound default />
          <Home path={ROOT_PATH} />
          <SessionRouter path={SESSION_PATH} />
          <SearchProducts path={SEARCH_PRODUCTS_PATH} />

          <SidebarSelection path={CATEGORY_LOOKUP_PATH}>
            <Promotions path=":pillar" />
            <TopLevelList path=":pillar/:toplevel" />
            <TopLevelSubList path=":pillar/:toplevel/:sublevel" />
            <TopLevelSubList2 path=":pillar/:toplevel/:sublevel/:subLevel2" />
            <TopLevelSubList3 path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3" />
            <TopLevelSubList4 path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4" />
            <TopLevelSubList5 path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4:/subLevel5" />
            <TopLevelSubList6 path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4:/subLevel5:/subLevel6" />
            <TopLevelSubList7 path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4:/subLevel5:/subLevel6:/subLevel7" />

            <Products path=":pillar/leaf/:leaf" pillarLeaf />
            <Products path=":pillar/:toplevel/leaf/:leaf" topLevelLeaf />
            <Products path=":pillar/:toplevel/:sublevel/leaf/:leaf" leafLevelLeaf />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/leaf/:leaf" leafLevelLeaf2 />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/leaf/:leaf" leafLevelLeaf3 />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4/leaf/:leaf" leafLevelLeaf4 />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4/:subLevel5/leaf/:leaf" leafLevelLeaf5 />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4/:subLevel5/:subLevel6/leaf/:leaf" leafLevelLeaf6 />
            <Products path=":pillar/:toplevel/:sublevel/:subLevel2/:subLevel3/:subLevel4/:subLevel5/:subLevel6/:subLevel7/leaf/:leaf" leafLevelLeaf7 />

            <Products path=":pillar/promoted/:category" pillarLeaf promoted />
          </SidebarSelection>

          <ManualLookup path={`${MANUAL_LOOKUP_PATH}/:domain`} />

          <BarcodeLookup path={`${BARCODE_LOOKUP_PATH}/barcode`} />

          <BarcodeProducts path={`${BARCODE_LOOKUP_PATH}/products`} />

          <VehicleDetails
            path={`${VRM_LOOKUP_PATH}/${VEHICLE_PATH}`}
            title="Your vehicle details"
            hasFooter={true}
            vrn={true}
            showSpec={true}
          />

          <VehicleDetails
            path={`${MANUAL_LOOKUP_PATH}/${VEHICLE_PATH}`}
            title="Your vehicle details"
            hasFooter={true}
            manual={true}
            showSpec={false}
          />
        </Router>
      )}
    </Suspense>
  );
};

export default AppRouter;
