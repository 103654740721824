import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { REPLACE_ACTIVE_NAVIGATION } from 'actions/actionTypes';
import { resetVehicleChangePreviousLocation } from 'actions/vehicleChangePreviousLocation';

const replaceCurrentWithPreviousNavigation: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case REPLACE_ACTIVE_NAVIGATION:
      store.dispatch(resetVehicleChangePreviousLocation());
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default replaceCurrentWithPreviousNavigation;
