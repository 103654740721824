import type { Props } from 'providers/partsFinderTill/types';
import type { UserAction } from 'providers/partsFinderTill/types';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { PARTS_FINDER_TILL } from 'libs/constants';
import { fromJSON, toJSON } from 'libs/json';

const { useState, createContext } = React;

const TillContext: React.Context<Object> = createContext({});

const TillProvider: React.StatelessFunctionalComponent<Props> = props => {
  const setLoggedIn = useState(false)[1];
  const dispatch = useDispatch();

  const loginUser: UserAction = dispatch => payload => {
    sessionStorage.setItem(PARTS_FINDER_TILL, toJSON(payload));
    setLoggedIn(true);
  };

  const value = {
    till: fromJSON(sessionStorage.getItem(PARTS_FINDER_TILL)),
    loginUser: loginUser(dispatch)
  };

  return (
    <TillContext.Provider value={value}>{props.children}</TillContext.Provider>
  );
};

export { TillContext as default, TillProvider };
