import type { AppState } from 'types';
import type { Selector } from 'selectors/types';

import { createSelector } from 'reselect';

const selectSidebarOpen = state => state.sidebar.isOpen;

export const isSidebarOpenSelector: Selector<
  AppState,
  boolean
> = createSelector(
  selectSidebarOpen,
  isOpen => isOpen
);
