import type { AppState } from 'types';
import type { Middleware, Action } from 'middlewares/types';
import {
  SESSION_CLEAR,
  SESSION_PAUSE,
  SESSION_RESUME,
  SESSION_START,
  SESSION_END
} from 'actions/session/actionTypes';
import { saveSession, loadSession } from 'hooks/useSession';

const stateToSession: Middleware<
  { session: AppState },
  Action
> = store => next => action => {
  next(action);

  /* post next(action) returns nested state 
    - store.getState() = { session: {session: { ... } } }
  */

  const { session, ...restState } = store.getState();
  const { session: sessionState } = session;
  const { paused } = loadSession();

  switch (action.type) {
    case SESSION_START:
      saveSession({
        active: {
          ...restState,
          session: sessionState,
          location: ''
        },
        paused
      });
      break;

    case SESSION_END:
      saveSession({
        active: {},
        paused
      });
      break;

    case SESSION_PAUSE:
      saveSession({
        active: {},
        paused: {
          ...restState,
          session: sessionState,
          location: window.location.pathname
        }
      });
      break;

    case SESSION_RESUME:
      saveSession({
        active: {
          ...paused,
          session: {
            ...paused.session,
            pausedSession: false,
            resumedSession: true
          }
        },
        paused: {}
      });
      break;

    case SESSION_CLEAR:
      saveSession({
        active: {},
        paused: {}
      });
      break;

    default:
      break;
  }
};
export default stateToSession;
