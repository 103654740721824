export const PARTS_FINDER_TILL = 'partsfinder-till';
export const PARTS_FINDER_SESSION = 'partsfinder-session';
export const CYCLE_REPUBLIC = 'CycleRepublic';
export const CYCLE_REPUBLIC_LOGO =
  'https://i1.adis.ws/i/washford/cr_header_logo';
export const NO_DATA = 'No data available';
export const NO_PRODUCT_DATA = 'Sorry, no results found';
export const NO_VRN_DATA = "Sorry we can't find this registration number";
export const REQUIRED_FIELD_MESSAGE = 'Field cannot be empty';
export const NUMBER_FIELD_MESSAGE = 'Field must contain numbers only';
export const MIN_FIELD_MESSAGE = 'Field value should be greater than ';
export const MAX_FIELD_MESSAGE = 'Field value should be less or equal to ';
export const INVALID_VRN_MESSAGE =
  "Sorry we can't find this registration number";
export const IMAGE_FALLBACK_URL = 'https://via.placeholder.com';
export const GBR = 'GBR';
export const GB = 'GB';
export const UK = 'UK';
export const IRL = 'IRL';
export const DEFAULT_PRICE = '0.00';
export const NUMBER_PLATES = 'numberplates';
export const NUMBER_PLATE = 'numberplate';
export const UNSPECIFIED_FITMENT_LOCATION = 'Other';
export const PAGE_SIZE = 6;

// BASKET
export const ADD_TO_TILL_SELL_BTN_TXT = 'Sell';
export const ADD_TO_TILL_ORDER_BTN_TXT = 'Order';
export const ADD_TO_TILL_RESERVE_BTN_TXT = 'Reserve';
export const ADD_TO_TILL_MORE_BTN_TXT = 'More info';
export const ZSAL_DEFAULT_STORE = 9100;

export const NONAPPLICABLE = 'N/A';
