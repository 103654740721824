import type { SearchState } from 'types';
import type { Reducer, ProductPayload } from 'reducers/types';
import type {
  FetchSeachFieldType,
  FetchSeachFieldSuccessType,
  FetchSeachFieldFailType,
  SecondaryFetchStartType,
  SecondaryFetchSuccessType,
  SecondaryFetchFailType,
  SetSearchTermType,
  SetSearchFilterType,
  ResetSearchType,
  SetSearchSelectionType,
  FetchSecondarySearchFieldType,
  FetchSecondarySearchFieldSuccessType,
  FetchSecondarySearchFieldFailType
} from 'actions/search/types';

import {
  RESET_SEARCH,
  SET_SEARCH_TERM,
  SET_SEARCH_FILTER,
  SET_SEARCH_SELECTION,
  FETCH_SEARCH_FIELD,
  CLEAR_SEARCH_SORT_FILTERS,
  CLEAR_SEARCH_FILTER,
  FETCH_SEARCH_FIELD_SUCCESS,
  FETCH_SEARCH_FIELD_FAIL
} from 'actions/search/actionTypes';
import { initState } from 'reducers';

type ActionType =
  | FetchSeachFieldType
  | FetchSeachFieldSuccessType
  | FetchSeachFieldFailType
  | SecondaryFetchStartType
  | SecondaryFetchSuccessType
  | SecondaryFetchFailType
  | SetSearchTermType
  | SetSearchFilterType
  | ResetSearchType
  | SetSearchSelectionType
  | FetchSecondarySearchFieldType
  | FetchSecondarySearchFieldSuccessType
  | FetchSecondarySearchFieldFailType;

type SearchPayload = ProductPayload & {
  term: string,
  selection: number,
  pageChange: Function,
  pageResultChange: Function,
  current: number,
  total: number,
  pageTotal: number,
  skipped: number,
  brandNames: string[],
  sort: string,
  pagination: {
    total: number,
    skipped: number,
    current: number,
    pageTotal: number
  },
  active: {
    term: string,
    selection: number
  }
};

const productsReducer: Reducer<
  SearchState,
  { type: ActionType, payload: SearchPayload }
> = (state = initState.search, { type, payload }) => {
  switch (type) {
    case RESET_SEARCH:
      return {
        ...initState.search
      };

    case CLEAR_SEARCH_SORT_FILTERS:
      return {
        ...state,
        filter: initState.search.filter,
        sort: initState.search.sort
      };

    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        filter: initState.search.filter
      };

    case SET_SEARCH_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload.filter
        }
      };

    case SET_SEARCH_TERM:
      return {
        ...state,
        isLoading: false,
        active: {
          ...state.active,
          term: payload.term
        }
      };

    case SET_SEARCH_SELECTION:
      state.filter = {
        brandName: '',
        vendor: ''
      };
      state.vendors = [];
      state.brandNames = [];
      return {
        ...state,
        isLoading: false,
        active: {
          ...state.active,
          selection: payload.selection
        },
        filter: {
          brandName: '',
          vendor: ''
        }
      };

    case FETCH_SEARCH_FIELD:
      let { filter, sort, brandNames, vendors } = state;
      const { active } = state;
      if (
        active.term !== payload.active.term ||
        active.selection !== payload.active.selection
      ) {
        filter = {
          brandName: '',
          vendor: ''
        };
        sort = '';
        brandNames = [];
        vendors = [];
      }
      return {
        ...state,
        isLoading: true,
        redirectPermitted: false,
        sort,
        filter,
        brandNames,
        vendors
      };

    case FETCH_SEARCH_FIELD_SUCCESS:
      const { pageChange, pageResultChange } = payload;
      let current = initState.search.pagination.current;

      if (!!pageChange) {
        current =
          payload.current > -1 ? payload.current : state.pagination.current;
      } else if (!!pageResultChange) {
        current = initState.search.pagination.current;
      } else {
        current =
          payload.current > -1 ? payload.current : state.pagination.current;
      }

      return {
        ...state,
        active: {
          term: payload.active.term,
          selection: payload.active.selection || state.active.selection
        },
        data: payload.products,
        pagination: {
          total: payload.total,
          skipped: payload.skipped,
          current,
          pageTotal: payload.pageTotal || state.pagination.pageTotal
        },
        sort: payload.sort || state.sort,
        brandNames: payload.brandNames,
        vendors: payload.vendors,
        isLoading: false,
        redirectPermitted: true,
        error: null
      };

    case FETCH_SEARCH_FIELD_FAIL:
      return {
        ...state,
        isLoading: false,
        data: [],
        brandNames: [],
        vendors: [],
        error: payload.error,
        redirectPermitted: false
      };

    default:
      return state;
  }
};

export default productsReducer;
