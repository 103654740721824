import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { isEmpty, get } from 'lodash/fp';
import { SET_NAV_SEARCH_DOMAIN } from 'actions/searchDomain/actionTypes';
import { resetVehicleRegNumber } from 'actions/vehicleRegNumber';
import { resetManualVehicleLookup } from 'actions/manualVehicleLookup';

const clearVehicleDataOnNavPillarChange: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case SET_NAV_SEARCH_DOMAIN:
      if (!!action.payload && action.payload.clearVehicleData) {
        const state = store.getState();

        const searchDomainIndexReference = state.searchDomain.indexReference;
        const vehicleRegData = state.vehicleRegNumber.data;
        const manualVehicleData = get('manualVehicleLookup.data[0]', state);
        const hasVehicleData =
          !isEmpty(vehicleRegData) || !isEmpty(manualVehicleData);

        if (hasVehicleData) {
          const currentVehicleIndexReference =
            get('indexReference', vehicleRegData) ||
            get('indexReference', manualVehicleData);

          if (currentVehicleIndexReference !== searchDomainIndexReference) {
            store.dispatch(resetVehicleRegNumber());
            store.dispatch(resetManualVehicleLookup());
          }
        }
      }
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default clearVehicleDataOnNavPillarChange;
