import type { AppState, Pagination, Product } from 'types';
import type { Selector } from 'selectors/types';

import { createSelector } from 'reselect';

const selectSearch = state => state.search.active;
const selectProducts = state => state.search.data;
const selectVendors = state => state.search.vendors;
const selectBrandNames = state => state.search.brandNames;
const selectSearchError = state => state.search.error;
const selectSearchLoading = state => state.search.isLoading;
const selectRedirectPermitted = state => state.search.redirectPermitted;
const selectPagination = state => state.search.pagination;
const selectSort = state => state.search.sort;
const selectFilter = state => state.search.filter;

export const loadingSelector: Selector<AppState, boolean> = createSelector(
  selectSearchLoading,
  isLoading => isLoading
);

export const errorSelector: Selector<AppState, null | string> = createSelector(
  selectSearchError,
  error => error
);

export const brandNameSelector: Selector<AppState, string[]> = createSelector(
  selectBrandNames,
  brands => brands
);

export const sortSelector: Selector<
  AppState,
  { name: string, variant: number[] }
> = createSelector(
  selectSort,
  sorter => sorter
);

export const filterSelector: Selector<
  AppState,
  { brandName: string, vendor: string }
> = createSelector(
  selectFilter,
  filter => filter
);

export const vendorsSelector: Selector<AppState, string[]> = createSelector(
  selectVendors,
  vendors => vendors
);

export const productsSelector: Selector<AppState, Product[]> = createSelector(
  selectProducts,
  products => products
);

export const redirectPermitted: Selector<AppState, boolean> = createSelector(
  selectRedirectPermitted,
  permitted => permitted
);

export const activeSearchSelector: Selector<
  AppState,
  { selection: number, term: string }
> = createSelector(
  selectSearch,
  active => active
);

export const paginationSelector: Selector<
  AppState,
  Pagination
> = createSelector(
  selectPagination,
  pagination => pagination
);
