import {
  SET_PERMIT_PRESET,
  UNSET_PERMIT_PRESET
} from 'actions/filterSettings/actionTypes';

export const setPermitPreset = () => ({
  type: SET_PERMIT_PRESET
});

export const unsetPermitPreset = () => ({
  type: UNSET_PERMIT_PRESET
});
