import type {
  UnsetSearchDomainType,
  SetSearchDomainType,
  SetNavSearchDomainType
} from 'actions/searchDomain/types';

export const UNSET_SEARCH_DOMAIN: UnsetSearchDomainType =
  '@@app/UNSET_SEARCH_DOMAIN';
export const SET_SEARCH_DOMAIN: SetSearchDomainType = '@@app/SET_SEARCH_DOMAIN';
export const SET_NAV_SEARCH_DOMAIN: SetNavSearchDomainType =
  '@@app/SET_NAV_SEARCH_DOMAIN';
