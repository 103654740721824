import type {
  SetPermitPresetActionType,
  UnsetPermitPresetActionType
} from 'actions/filterSettings/types';

export const SET_PERMIT_PRESET: SetPermitPresetActionType =
  '@@app/filters/SET_PERMIT_PRESET';

export const UNSET_PERMIT_PRESET: UnsetPermitPresetActionType =
  '@@app/filters/UNSET_PERMIT_PRESET';
