import './styles.scss';
import type { Props } from 'components/Button/types';

import * as React from 'react';
import { Button } from '@material-ui/core';
import { noop } from 'lodash/fp';

const Btn: React.StatelessFunctionalComponent<Props> = ({
  disabled = false,
  size = 'medium',
  variant = 'contained',
  onClick = noop,
  children,
  className,
  ...props
}) => (
  <Button
    size={size}
    variant={variant}
    className={className}
    disabled={disabled}
    type="button"
    onClick={onClick}
    disableRipple={true}
    {...props}>
    {children}
  </Button>
);

export default Btn;
