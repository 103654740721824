import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { SET_ACTIVE_NAVIGATION } from 'actions/navigation/actionTypes';
import { clearProductPagination } from 'actions/products';

const clearPaginationOnNavChange: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case SET_ACTIVE_NAVIGATION:
      store.dispatch(clearProductPagination());
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default clearPaginationOnNavChange;
