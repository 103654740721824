import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/modules/es.object.from-entries';
import 'core-js/modules/es.string.repeat';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'configureStore';
import App from 'app';
import { initState } from 'reducers';
import { TillProvider } from 'providers/partsFinderTill';

const root: ?Element = document.querySelector('#partsfinder-root');
export const store = configureStore(initState);

if (!!root) {
  /* clearing session storage before mounting app - iPose not clearing session storage on closing app */
  sessionStorage.clear();

  ReactDOM.render(
    <Provider store={store}>
      <TillProvider>
        <App />
      </TillProvider>
    </Provider>,
    root
  );
}
