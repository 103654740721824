import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { SET_ACTIVE_NAVIGATION } from 'actions/navigation/actionTypes';
import { FETCH_SEARCH_FIELD } from 'actions/search/actionTypes';
import { CLEAR_PRODUCT_FILTER } from 'actions/products/actionTypes';
import { CLEAR_SEARCH_FILTER } from 'actions/search/actionTypes';
import { setPermitPreset, unsetPermitPreset } from 'actions/filterSettings';

const filterSettingsMiddleware: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case FETCH_SEARCH_FIELD:
      const { search } = store.getState();
      if (
        JSON.stringify(search.active) !== JSON.stringify(action.payload.active)
      ) {
        store.dispatch(setPermitPreset());
      }
      next(action);
      break;

    case SET_ACTIVE_NAVIGATION:
      store.dispatch(setPermitPreset());
      next(action);
      break;

    case CLEAR_PRODUCT_FILTER:
    case CLEAR_SEARCH_FILTER:
      store.dispatch(unsetPermitPreset());
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default filterSettingsMiddleware;
