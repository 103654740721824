import type {
  FetchVendors,
  FetchVendorsSuccess,
  FetchVendorsFail
} from 'actions/vendors/types';

export const FETCH_VENDORS: FetchVendors = '@@store/vendors/FETCH_VENDORS';
export const FETCH_VENDORS_SUCCESS: FetchVendorsSuccess =
  '@@store/vendors/FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAIL: FetchVendorsFail =
  '@@store/vendors/FETCH_VENDORS_FAIL';
