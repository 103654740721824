import type { Reducer } from 'reducers/types';
import type { FilterSettingsState } from 'types';
import type {
  SetPermitPresetActionType,
  UnsetPermitPresetActionType
} from 'actions/filterSettings/types';

import {
  SET_PERMIT_PRESET,
  UNSET_PERMIT_PRESET
} from 'actions/filterSettings/actionTypes';
import { initState } from 'reducers';

const fiterSettingsReducer: Reducer<
  FilterSettingsState,
  {
    type: SetPermitPresetActionType | UnsetPermitPresetActionType
  }
> = (state = initState.filterSettings, { type }) => {
  switch (type) {
    case SET_PERMIT_PRESET:
      return { ...state, isPresetAllowed: true };

    case UNSET_PERMIT_PRESET:
      return { ...state, isPresetAllowed: false };

    default:
      return state;
  }
};

export default fiterSettingsReducer;
