import type {
  FetchProductsType,
  FetchProductsSuccessType,
  FetchProductsFailType,
  SetProductsSortType,
  SetProductsFilterType,
  ResetProductsType,
  ClearProductsSortFilterType,
  ClearProductsPaginationType,
  ClearProductsFilterType
} from 'actions/products/types';

export const CLEAR_PRODUCT_PAGINATION: ClearProductsPaginationType =
  '@@sidebar/products/CLEAR_PRODUCT_PAGINATION';
export const CLEAR_PRODUCTS_SORT_FILTER: ClearProductsSortFilterType =
  '@@sidebar/products/CLEAR_PRODUCTS_SORT_FILTER';
export const CLEAR_PRODUCT_FILTER: ClearProductsFilterType =
  '@@sidebar/products/CLEAR_PRODUCTS_FILTER';
export const RESET_PRODUCTS: ResetProductsType =
  '@@sidebar/products/RESET_PRODUCTS';
export const FETCH_PRODUCTS: FetchProductsType =
  '@@sidebar/products/FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS: FetchProductsSuccessType =
  '@@sidebar/products/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL: FetchProductsFailType =
  '@@sidebar/products/FETCH_PRODUCTS_FAIL';
export const SET_PRODUCTS_SORT: SetProductsSortType =
  '@@sidebar/products/SET_PRODUCTS_SORT';
export const SET_PRODUCTS_FILTER: SetProductsFilterType =
  '@@sidebar/products/SET_PRODUCTS_FILTER';
