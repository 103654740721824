// import './styles.scss';
import type { Props } from 'components/Spinner/types';

import * as React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const Spinner: React.StatelessFunctionalComponent<Props> = ({
  visible = false,
  size = 48
}) =>
  visible && (
    <Grid container direction="row" justify="center" alignItems="center">
      <CircularProgress color="secondary" size={size} />
    </Grid>
  );

export default Spinner;
