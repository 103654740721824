import type { UseSidebar } from 'hooks/useSidebar/types';

import { useSelector, useDispatch } from 'react-redux';
import { isSidebarOpenSelector } from 'selectors/sidebar';
import toggleSidebarAction from 'actions/sidebar';

const useSidebar: UseSidebar = () => {
  const isOpen = useSelector(isSidebarOpenSelector);
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(toggleSidebarAction());
  };

  return [isOpen, toggleSidebar];
};

export default useSidebar;
