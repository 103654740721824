import type { UseFiltering } from 'app/search/types';

import useTill from 'hooks/useTill';
import { useSelector } from 'react-redux';
import { activeSearchSelector, paginationSelector } from 'selectors/search';

const useFiltering: UseFiltering = () => {
  const { till } = useTill();
  const currentSearch = useSelector(activeSearchSelector);
  const pagination = useSelector(paginationSelector);

  const { selection, term } = currentSearch;
  const isProductSearch = selection !== -1;

  const baseArgs = {
    isProductSearch,
    storeIds: till.store,
    [isProductSearch ? selection : 'query']: term,
    search: currentSearch,
    size: pagination.pageTotal,
    page: pagination.current
  };

  return [baseArgs, currentSearch];
};

export default useFiltering;
