import type { ProductsState } from 'types';
import type { Reducer, ProductPayload } from 'reducers/types';
import type {
  ResetProductsType,
  ClearProductsSortFilterType,
  FetchProductsType,
  FetchProductsSuccessType,
  FetchProductsFailType,
  SetProductsSortType,
  SetProductsFilterType
} from 'actions/products/types';

import {
  RESET_PRODUCTS,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
  SET_PRODUCTS_SORT,
  SET_PRODUCTS_FILTER,
  CLEAR_PRODUCTS_SORT_FILTER,
  CLEAR_PRODUCT_FILTER,
  CLEAR_PRODUCT_PAGINATION
} from 'actions/products/actionTypes';
import { initState } from 'reducers';

type ActionType =
  | ResetProductsType
  | FetchProductsType
  | FetchProductsSuccessType
  | FetchProductsFailType
  | SetProductsSortType
  | SetProductsFilterType
  | ClearProductsSortFilterType;

const productsReducer: Reducer<
  ProductsState,
  {
    type: ActionType,
    payload: ProductPayload
  }
> = (state = initState.products, { type, payload }) => {
  switch (type) {
    case SET_PRODUCTS_FILTER: {
      return {
        ...state,
        filter: payload.filter
      };
    }

    case SET_PRODUCTS_SORT: {
      return {
        ...state,
        sort: payload.sort
      };
    }

    case RESET_PRODUCTS: {
      return {
        ...initState.products
      };
    }

    case CLEAR_PRODUCT_FILTER: {
      return {
        ...state,
        filter: initState.products.filter
      };
    }

    case CLEAR_PRODUCT_PAGINATION: {
      return {
        ...state,
        pagination: initState.products.pagination
      };
    }

    case CLEAR_PRODUCTS_SORT_FILTER: {
      return {
        ...state,
        filter: initState.products.filter,
        sort: initState.products.sort
      };
    }

    case FETCH_PRODUCTS: {
      const { resetFilters } = payload;
      return {
        ...initState.products,
        isLoading: true,
        filter: resetFilters ? initState.products.filter : state.filter,
        sort: resetFilters ? initState.products.sort : state.sort
      };
    }

    case FETCH_PRODUCTS_SUCCESS: {
      const {
        products: data,
        vendors,
        brands: brandNames,
        typeNames,
        bundles,
        bulbFitment,
        fittingInstructions,
        notes,
        total,
        skipped,
        pageTotal,
        current
      } = payload;

      return {
        ...state,
        data,
        vendors,
        brandNames,
        bundles,
        bulbFitment,
        notes,
        fittingInstructions,
        typeNames,
        pagination: !isNaN(total)
          ? { total, skipped, pageTotal, current }
          : initState.products.pagination,
        isLoading: false,
        error: null
      };
    }

    case FETCH_PRODUCTS_FAIL: {
      const {
        data,
        vendors,
        bundles,
        pagination,
        bulbFitment,
        notes,
        fittingInstructions
      } = initState.products;

      return {
        ...state,
        data,
        vendors,
        bundles,
        pagination,
        bulbFitment,
        notes,
        fittingInstructions,
        isLoading: false,
        error: payload.error
      };
    }

    default:
      return state;
  }
};

export default productsReducer;
