import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { FETCH_PRODUCTS } from 'actions/products/actionTypes';
import { FETCH_SEARCH_FIELD } from 'actions/search/actionTypes';
import { resetProducts } from 'actions/products';
import { resetSearch } from 'actions/search';

const clearSortFiltersOnNavChange: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      store.dispatch(resetSearch());
      next(action);
      break;

    case FETCH_SEARCH_FIELD:
      store.dispatch(resetProducts());
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default clearSortFiltersOnNavChange;
