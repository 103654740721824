import type { VehicleChangePreviousLocationState } from 'types';
import type { Reducer } from 'reducers/types';
import type {
  SetPreviousVehicleLookupLocationActionType,
  ResetPreviousVehicleLookupLocationActionType
} from 'actions/vehicleChangePreviousLocation/types';

import {
  RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION,
  SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION
} from 'actions/vehicleChangePreviousLocation/actionTypes';
import { initState } from 'reducers';

const vehicleChangePreviousLocationReducer: Reducer<
  VehicleChangePreviousLocationState,
  {
    type:
      | SetPreviousVehicleLookupLocationActionType
      | ResetPreviousVehicleLookupLocationActionType,
    payload: { location: { category: string | null, isLeaf: boolean }[] }
  }
> = (state = initState.vehicleChangePreviousLocation, { type, payload }) => {
  switch (type) {
    case RESET_PREVIOUS_VEHICLE_LOOKUP_LOCATION: {
      return { ...initState.vehicleChangePreviousLocation };
    }
    case SET_PREVIOUS_VEHICLE_LOOKUP_LOCATION:
      return { ...state, location: payload.location };
    default:
      return state;
  }
};

export default vehicleChangePreviousLocationReducer;
