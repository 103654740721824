/* @flow */
import './styles.scss';

import * as React from 'react';
import { MenuItem, createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

const { forwardRef, useRef } = React;

const MenuListItem = forwardRef<*, *>((props: any, ref: React.Ref<any>) => {
  const innerRef = useRef(ref);
  return (
    <MuiThemeProvider theme={theme}>
      <MenuItem {...props} ref={innerRef}>
        {props.children}
      </MenuItem>
    </MuiThemeProvider>
  );
});

export default MenuListItem;
