import type { Reducer } from 'reducers/types';
import type { PromotedState, Hotlink } from 'types';
import type { SetPromotedTpye } from 'actions/promoted/types';

import { SET_PROMOTED } from 'actions/promoted/actionTypes';
import { initState } from 'reducers';

const promotedReducer: Reducer<
  PromotedState,
  { type: SetPromotedTpye, payload: Hotlink }
> = (state = initState.promoted, { type, payload }) => {
  switch (type) {
    case SET_PROMOTED:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
};

export default promotedReducer;
