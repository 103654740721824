export const ROOT_PATH = '/';
export const SESSION_PATH = 'session';
export const SEARCH_PRODUCTS_PATH = 'search/products';
export const CATEGORY_LOOKUP_PATH = 'nav_lookup';
export const MANUAL_LOOKUP_PATH = 'manual_lookup';
export const BARCODE_LOOKUP_PATH = 'barcode_lookup';
export const VRM_LOOKUP_PATH = 'vrm_lookup';

export const MOTORING_DOMAIN_PATH = 'motoring';
export const MOTORBIKES_DOMAIN_PATH = 'motorbikes';
export const CYCLING_DOMAIN_PATH = 'cycling';
export const CARSEATS_DOMAIN_PATH = 'carseats';
export const VEHICLE_PATH = 'vehicle';
export const MANUAL_LOOKUP_FORM_PATH = '/';
export const NO_MATCH_PATH = '*';
