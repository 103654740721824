import type { NavigationState, NavigationCategory } from 'types';
import type { Reducer, PayloadError } from 'reducers/types';
import type {
  SetNavigationType,
  UnSetNavigationType,
  FetchNavigationType,
  FetchNavigationSuccessType,
  FetchNavigationFailType
} from 'actions/navigation/types';
import type { ReplaceActiveNavigationType } from 'actions/types';
import {
  SET_ACTIVE_NAVIGATION,
  UNSET_NAVIGATION,
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_NAVIGATION_FAIL
} from 'actions/navigation/actionTypes';
import { REPLACE_ACTIVE_NAVIGATION } from 'actions/actionTypes';

type ActionType =
  | SetNavigationType
  | UnSetNavigationType
  | FetchNavigationType
  | FetchNavigationSuccessType
  | FetchNavigationFailType
  | ReplaceActiveNavigationType;

const nullSelection = { category: null, isLeaf: false };

export const initState = {
  loading: false,
  data: [],
  active: [nullSelection, nullSelection, nullSelection, nullSelection, nullSelection, nullSelection, nullSelection, nullSelection, nullSelection, nullSelection],
  error: null
};

const navigationReducer: Reducer<
  NavigationState,
  {
    type: ActionType,
    payload: {
      data: [] | NavigationCategory[],
      depth: number,
      selection: string,
      isLeaf: boolean,
      active: {
        [key: string]: { category: null | string, isLeaf: boolean }[]
      },
      locations: { category: null | string, isLeaf: boolean }[],
      error: PayloadError
    }
  }
> = (state = initState, { type, payload }) => {
  switch (type) {
    case UNSET_NAVIGATION:
      return { ...state, active: initState.active };

    case REPLACE_ACTIVE_NAVIGATION:
      return { ...state, active: payload.locations };

    case SET_ACTIVE_NAVIGATION:
      const { active } = state;

      if (active[payload.depth].category === payload.selection) {
        return {
          ...state,
          active: [
            ...active.slice(0, payload.depth),
            nullSelection,
            ...active.slice(payload.depth + 1).fill(nullSelection)
          ]
        };
      }

      return {
        ...state,
        active: [
          ...active.slice(0, payload.depth),
          { category: payload.selection, isLeaf: payload.isLeaf },
          ...active.slice(payload.depth + 1).fill(nullSelection)
        ]
      };

    case FETCH_NAVIGATION:
      return {
        ...state,
        loading: true
      };

    case FETCH_NAVIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload.data
      };

    case FETCH_NAVIGATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error
      };

    default:
      return state;
  }
};

export default navigationReducer;
