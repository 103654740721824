import type { AppState } from 'types';
import type { Middleware } from 'middlewares/types';

import { isEmpty, get } from 'lodash/fp';
import { SET_ACTIVE_NAVIGATION } from 'actions/navigation/actionTypes';
import { clearProductSortFilters } from 'actions/products';
import { clearSearchSortFilters } from 'actions/search';

const clearSortFiltersOnNavChange: Middleware<
  AppState,
  | {
      type: any,
      payload: any
    }
  | { type: any }
> = store => next => action => {
  switch (action.type) {
    case SET_ACTIVE_NAVIGATION:
      const { products, search } = store.getState();
      const searchFilters = Object.keys(search.filter).filter(
        filterName => !!search.filter[filterName]
      );
      const productFilters = Object.keys(products.filter).filter(
        filterName => !!get(`filter.${filterName}`, products)
      );

      if (!isEmpty(productFilters)) {
        store.dispatch(clearProductSortFilters());
      }
      if (!isEmpty(searchFilters)) {
        store.dispatch(clearSearchSortFilters());
      }
      next(action);
      break;

    default:
      next(action);
      break;
  }
};

export default clearSortFiltersOnNavChange;
