import type { NavListItemProps } from 'app/sidebar/types';

import * as React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'uuid/v4';
import { snakeCase, toLower, isEmpty, get } from 'lodash/fp';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  setSearchDomainReference,
  setSearchDomainReferenceFromNav
} from 'actions/searchDomain';
import { searchDomainSelector } from 'selectors/searchDomain';
import { selectedVehicleSelector } from 'selectors/manualVehicleLookup';
import { vehicleRegNumberSelector } from 'selectors/vehicleRegNumber';
import { selectionHandler } from 'app/sidebar/selectionHandlers';
import useActions from 'hooks/useActions';
import useSidebar from 'hooks/useSidebar';
import trimSpace from 'libs/trimSpace';
import { NUMBER_PLATES } from 'libs/constants';

const { Fragment, createRef, useEffect } = React;

function concatFormattedPath(basePath, appendPath) {
  const navRoute = !!appendPath ? `${basePath}/${appendPath}` : basePath;
  return toLower(navRoute);
}

const NavListItem: React.StatelessFunctionalComponent<NavListItemProps> = ({
  item,
  selected,
  onSelect,
  maxloop,
  loopcount = 0,
  basePath,
  nested = false
}) => {
  const linkRef = createRef();
  const [isSidebarOpen, toggleSidebar] = useSidebar();
  const setAutoSelectCategory = useActions(setSearchDomainReference);
  const searchDomainRefFromNavDispatch = useDispatch();
  const { autoSelectCategory, domain, indexReference } = useSelector(
    searchDomainSelector
  );

  const manualVehicleData = useSelector(selectedVehicleSelector);
  const regVehicleData = useSelector(vehicleRegNumberSelector);

  const next = loopcount + 1;
  const showChildren = !isEmpty(item.childCategories) && loopcount < maxloop;
  const [pillar, top, sub, leaf, leaf2, leaf3, leaf4, leaf5, leaf6, leaf7] = selected;

  const numberPlatesDomain = trimSpace(toLower(domain)) === NUMBER_PLATES;

  let isTopAutoSelected =
    loopcount === 0 &&
    trimSpace(toLower(item.nodeName)) === trimSpace(toLower(domain)) &&
    autoSelectCategory;

  const isPillarOpen =
    !!pillar.category && loopcount === 0 && pillar.category === item.categoryId;

  const isTopOpen =
    !!top.category && loopcount === 1 && top.category === item.categoryId;

  const isSubOpen =
    !!sub.category && loopcount === 2 && sub.category === item.categoryId;

  const isLeafOpen =
    !!leaf.category &&
    loopcount === 3 &&
    leaf.category === item.categoryId;

  const isLeaf2Open =
    !!leaf2.category &&
    loopcount === 4 &&
    leaf2.category === item.categoryId;

  const isLeaf3Open =
    !!leaf3.category &&
    loopcount === 5 &&
    leaf3.category === item.categoryId;

  const isLeaf4Open =
    !!leaf4.category &&
    loopcount === 6 &&
    leaf4.category === item.categoryId;

    const isLeaf5Open =
    !!leaf5.category &&
    loopcount === 7 &&
    leaf5.category === item.categoryId;

    const isLeaf6Open =
    !!leaf6.category &&
    loopcount === 8 &&
    leaf6.category === item.categoryId;

    const isLeaf7Open =
    !!leaf7.category &&
    loopcount === 9 &&
    leaf7.category === item.categoryId;


  const open = isPillarOpen || isTopOpen || isSubOpen || isLeafOpen || isLeaf2Open || isLeaf3Open || isLeaf4Open || isLeaf5Open || isLeaf6Open || isLeaf7Open ;

  let linkPath = basePath
    ? concatFormattedPath(
        `${basePath}${item.nodeType === 'leaf' ? '/leaf' : ''}`,
        snakeCase(toLower(item.nodeName))
      )
    : concatFormattedPath(snakeCase(toLower(item.nodeName)));

  useEffect(() => {
    if (isTopAutoSelected) {
      const current = get('current', linkRef);
      if (current && !isPillarOpen) {
        current.click();
        if (!numberPlatesDomain) {
          setAutoSelectCategory(indexReference);
        }
      }
    }
  }, [
    indexReference,
    isPillarOpen,
    isTopAutoSelected,
    linkRef,
    numberPlatesDomain,
    setAutoSelectCategory
  ]);

  useEffect(() => {
    if (
      pillar.category &&
      item.categoryId === pillar.category &&
      !top.category &&
      !sub.category &&
      !leaf.category &&
      !leaf2.category &&
      !leaf3.category &&
      !leaf4.category &&
      !leaf5.category &&
      !leaf6.category &&
      !leaf7.category
    ) {
      const hasVehicleData =
        !isEmpty(regVehicleData) || !isEmpty(manualVehicleData);

      const { indexReference: vehicleIndexReference } =
        regVehicleData || manualVehicleData;

      const clearVehicleData =
        hasVehicleData && indexReference !== vehicleIndexReference;

      searchDomainRefFromNavDispatch(
        setSearchDomainReferenceFromNav(
          trimSpace(toLower(item.nodeName)),
          false,
          clearVehicleData
        )
      );
    }
  }, [
    indexReference,
    item.categoryId,
    item.nodeName,
    leaf.category,
    leaf2.category,
    leaf3.category,
    leaf4.category,
    leaf5.category,
    leaf6.category,
    leaf7.category,
    manualVehicleData,
    pillar.category,
    regVehicleData,
    searchDomainRefFromNavDispatch,
    sub.category,
    top.category
  ]);
  const isLeaf = toLower(item.nodeType) === 'leaf';
  const onClickHandler = selectionHandler({
    onSelect,
    depth: loopcount,
    selection: item.categoryId,
    isLeaf,
    path: linkPath,
    open
  });

  const collapseSidebar = evt => {
    if (isSidebarOpen && isLeaf) {
      toggleSidebar();
    }
    onClickHandler(evt);
  };

  return (
    <Fragment>
      <ListItem
        selected={open}
        button
        disableRipple
        ref={linkRef}
        onClick={collapseSidebar}
        className={classNames({
          'nested__level--1': nested && loopcount === 1,
          'nested__level--2': nested && loopcount === 2,
          'nested__level--3': nested && loopcount === 3,
          'nested__level--4': nested && loopcount === 4,
          'nested__level--5': nested && loopcount === 5,
          'nested__level--6': nested && loopcount === 6,
          'nested__level--7': nested && loopcount === 7,
          'nested__level--8': nested && loopcount === 8,
          'nested__level--9': nested && loopcount === 9,
          selected: open,
          'leaf--true': isLeaf,
          'selected--leaf': isLeaf && open
        })}>
        <ListItemText primary={item.nodeName} />
        {showChildren ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {showChildren && (
        <Fragment>
          <Divider />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.childCategories.map(child => (
                <NavListItem
                  key={uuid()}
                  item={child}
                  maxloop={maxloop}
                  loopcount={next}
                  onSelect={onSelect}
                  selected={selected}
                  basePath={linkPath}
                  nested={true}
                />
              ))}
            </List>
          </Collapse>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NavListItem;
