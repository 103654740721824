import type { ManualLookupState, ObjectShape } from 'types';
import type { Reducer, PayloadError } from 'reducers/types';
import type {
  ResetManualVehicleLookupType,
  FetchManualVehicleLookupType,
  FetchManualVehicleLookupSuccessType,
  FetchManualVehicleLookupFailType,
  SetSelectedFromLookupVehiclesType,
  UnsetManualVehicleLookupRedirectType
} from 'actions/manualVehicleLookup/types';

import {
  FETCH_MANUAL_VECHICLE_LOOKUP,
  FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS,
  FETCH_MANUAL_VECHICLE_LOOKUP_FAIL,
  UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT,
  SET_MANUAL_VECHICLE_LOOKUP_SELECTED,
  RESET_MANUAL_VECHICLE_LOOKUP
} from 'actions/manualVehicleLookup/actionTypes';
import { initState } from 'reducers';

type ActionType =
  | ResetManualVehicleLookupType
  | FetchManualVehicleLookupType
  | FetchManualVehicleLookupSuccessType
  | FetchManualVehicleLookupFailType
  | SetSelectedFromLookupVehiclesType
  | UnsetManualVehicleLookupRedirectType;

const manualVehicleLookupReducer: Reducer<
  ManualLookupState,
  {
    type: ActionType,
    payload: {
      data?: ObjectShape | {},
      error?: PayloadError,
      selected?: number
    }
  }
> = (state = initState.manualVehicleLookup, { type, payload }) => {
  switch (type) {
    case RESET_MANUAL_VECHICLE_LOOKUP:
      return { ...initState.manualVehicleLookup };

    case FETCH_MANUAL_VECHICLE_LOOKUP:
      return {
        ...state,
        isLoading: true,
        fetchComplete: false
      };

    case FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        data: payload.data,
        error: null
      };

    case FETCH_MANUAL_VECHICLE_LOOKUP_FAIL:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        data: {},
        selected: -1,
        error: payload.error
      };

    case SET_MANUAL_VECHICLE_LOOKUP_SELECTED:
      return {
        ...state,
        selected: payload.selected,
        redirectPermitted: true
      };

    case UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT:
      return {
        ...state,
        redirectPermitted: false
      };

    default:
      return state;
  }
};

export default manualVehicleLookupReducer;
