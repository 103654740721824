import type { AppState, SessionState } from 'types';
import type { Selector } from 'selectors/types';
import type { SelectSession } from 'selectors/session/types';

import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

const selectSession: SelectSession = state => {
  /* weird selector because the partsfindermiddleware return nested state 
    - state = { session: {session: { ... } } }
    and when not running past the middleware types we get normal state
    - state = { session: { ... } }
  */
  return get('session.session', state) || get('session', state);
};

export const sessionActionSelector: Selector<
  AppState,
  SessionState
> = createSelector(
  selectSession,
  session => session
);
