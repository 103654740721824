import type {
  LoadSession,
  SaveSession,
  ClearActiveSessionLocation
} from 'hooks/useSession/types';
import { PARTS_FINDER_SESSION } from 'libs/constants';
import { fromJSON, toJSON } from 'libs/json';

const DEFAULT_SESSION = { active: {}, paused: {} };

const loadSession: LoadSession = () => {
  const serialisedState =
    sessionStorage.getItem(PARTS_FINDER_SESSION) || toJSON(DEFAULT_SESSION);

  return fromJSON(serialisedState);
};

const saveSession: SaveSession = state => {
  try {
    const serialisedState = toJSON(state);
    sessionStorage.setItem(PARTS_FINDER_SESSION, serialisedState);
  } catch (error) {
    // capture analytic
  }
};

const clearActiveSessionLocation: ClearActiveSessionLocation = (
  activeSession,
  pausedSession
) => {
  try {
    const state = {
      active: {
        ...activeSession,
        location: ''
      },
      paused: pausedSession
    };

    const serialisedState = toJSON(state);
    sessionStorage.setItem(PARTS_FINDER_SESSION, serialisedState);
  } catch (error) {
    // capture analytic
  }
};

const useSession = () => {
  const { active, paused } = loadSession() || {
    active: {},
    paused: {}
  };

  return {
    active,
    paused,
    sessionLabel: PARTS_FINDER_SESSION
  };
};

export {
  useSession as default,
  DEFAULT_SESSION,
  loadSession,
  clearActiveSessionLocation,
  saveSession
};
