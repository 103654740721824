import type {
  SetNavigationType,
  UnSetNavigationType,
  FetchNavigationType,
  FetchNavigationSuccessType,
  FetchNavigationFailType
} from 'actions/navigation/types';

export const SET_ACTIVE_NAVIGATION: SetNavigationType =
  '@@sidebar/SET_ACTIVE_NAVIGATION';

export const UNSET_NAVIGATION: UnSetNavigationType =
  '@@sidebar/UNSET_NAVIGATION';

export const FETCH_NAVIGATION: FetchNavigationType =
  '@@sidebar/FETCH_NAVIGATION';

export const FETCH_NAVIGATION_SUCCESS: FetchNavigationSuccessType =
  '@@sidebar/FETCH_NAVIGATION_SUCCESS';

export const FETCH_NAVIGATION_FAIL: FetchNavigationFailType =
  '@@sidebar/FETCH_NAVIGATION_FAIL';
