import type { Reducer } from 'reducers/types';
import type { BreadcrumbsState, BreadcrumbItem } from 'types';

import { SET_BREADCRUMBS_LIST } from 'actions/breadcrumbs/actionTypes';
import { initState } from 'reducers';

type ActionType = string;

const breadcrumbsReducer: Reducer<
  BreadcrumbsState,
  { type: ActionType, payload: BreadcrumbItem[] }
> = (state = initState.breadcrumbs, { type, payload }) => {
  switch (type) {
    case SET_BREADCRUMBS_LIST:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
};

export default breadcrumbsReducer;
