import type { Selector } from 'selectors/types';
import type { AppState, BreadcrumbItem } from 'types';

import { createSelector } from 'reselect';

const selectBreadcrumbs = state => state.breadcrumbs.data;

export const breadcrumbsSelector: Selector<
  AppState,
  BreadcrumbItem[]
> = createSelector(
  [selectBreadcrumbs],
  breadcrumbs => breadcrumbs
);
