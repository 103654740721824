import type { FetchActionInit, FetchActionResponse } from 'actions/types';
import type { Product, FetchError } from 'types';

import manualVehicleLookupAPI from 'api/manualVehicleLookup';

import {
  FETCH_MANUAL_VECHICLE_LOOKUP,
  FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS,
  FETCH_MANUAL_VECHICLE_LOOKUP_FAIL,
  UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT,
  SET_MANUAL_VECHICLE_LOOKUP_SELECTED,
  RESET_MANUAL_VECHICLE_LOOKUP
} from 'actions/manualVehicleLookup/actionTypes';

import type {
  ManualVehicleLookupAction,
  ResetManualVehicleLookupType,
  FetchManualVehicleLookupType,
  FetchManualVehicleLookupSuccessType,
  FetchManualVehicleLookupFailType,
  UnsetManualVehicleLookupRedirectType,
  SetSelectedFromLookupVehiclesType
} from 'actions/manualVehicleLookup/types';

const resetManualVehicleLookup: FetchActionInit<ResetManualVehicleLookupType> = () => ({
  type: RESET_MANUAL_VECHICLE_LOOKUP
});

const fetchManualVehicleLookup: FetchActionInit<FetchManualVehicleLookupType> = () => ({
  type: FETCH_MANUAL_VECHICLE_LOOKUP
});

const fetchManualVehicleLookupSuccess: FetchActionResponse<
  { data: Product[] },
  FetchManualVehicleLookupSuccessType
> = payload => ({
  type: FETCH_MANUAL_VEHICLE_LOOKUP_SUCCESS,
  payload
});

const fetchManualVehicleLookupFail: FetchActionResponse<
  FetchError,
  FetchManualVehicleLookupFailType
> = payload => ({
  type: FETCH_MANUAL_VECHICLE_LOOKUP_FAIL,
  payload
});

const unsetManualVehicleLookupRedirect: FetchActionInit<UnsetManualVehicleLookupRedirectType> = () => ({
  type: UNSET_MANUAL_VECHICLE_LOOKUP_REDIRECT
});

const setSelectedFromLookupVehicles: FetchActionResponse<
  {},
  SetSelectedFromLookupVehiclesType
> = payload => ({
  type: SET_MANUAL_VECHICLE_LOOKUP_SELECTED,
  payload
});

const manualVehicleLookup: ManualVehicleLookupAction = args => async dispatch => {
  dispatch(fetchManualVehicleLookup());

  try {
    let data;
    const { indexReference: formArgs, ...lookupArgs } = args;

    const { baseResponse, entities } = await manualVehicleLookupAPI({
      ...lookupArgs,
      indexReference: formArgs.indexReference
    });

    const { year } = lookupArgs;

    if (baseResponse.resultText === 'success') {
      if (entities.length) {
        data = entities.map(entity => {
          entity.attributes.year = year;
          return entity;
        });
      } else {
        data = [];
      }
    } else {
      data = [];
    }

    dispatch(fetchManualVehicleLookupSuccess({ data }));
  } catch (error) {
    dispatch(fetchManualVehicleLookupFail({ error: error.message }));
  }
};

export {
  manualVehicleLookup as default,
  unsetManualVehicleLookupRedirect,
  setSelectedFromLookupVehicles,
  resetManualVehicleLookup
};
