// @flow
import type { Props } from 'app/sidebar/types';

import * as React from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';
import { toLower } from 'lodash/fp';
import classNames from 'classnames';
// $FlowFixMe
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import NavListItem from 'app/sidebar/ListItem';
import { onNavigationSelect } from 'app/sidebar/selectionHandlers';
import { setActiveNavigation } from 'actions/navigation';
import { activeNavSelector, menuSelector } from 'selectors/navigation';
import useActions from 'hooks/useActions';
import { NAV_DEPTH } from 'config/sidebar';
import searchDomains, { CYCLING_INDEX_REFERENCE } from 'config/searchDomain';
import { NUMBER_PLATES } from 'libs/constants';
import trimSpace from 'libs/trimSpace';

const { memo } = React;

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 420,
    backgroundColor: 'inherit'
  }
}));

const Sidebar = memo<Props>(({ isCycleRepublic, isNumberPlates }) => {
  const selected = useSelector(activeNavSelector);
  const setSelected = useActions(setActiveNavigation);
  const sidebarList = useSelector(menuSelector);

  const classes = useStyles();

  const filteredNavigation = sidebarList.filter(({ nodeName, categoryId }) => {
    if (isCycleRepublic) {
      const [{ domain }] = searchDomains.filter(
        ({ indexReference }) => indexReference === CYCLING_INDEX_REFERENCE
      );

      return toLower(trimSpace(domain)) === toLower(trimSpace(nodeName));
    }

    if (isNumberPlates) {
      return toLower(trimSpace(NUMBER_PLATES)) === toLower(trimSpace(nodeName));
    }

    return toLower(trimSpace(NUMBER_PLATES)) !== toLower(trimSpace(nodeName));
  });

  return (
    <List
      component="nav"
      className={classNames(classes.root, 'nested__level--0')}>
      {filteredNavigation.map(item => (
        <NavListItem
          key={uuid()}
          selected={selected}
          onSelect={onNavigationSelect(setSelected)}
          item={item}
          maxloop={NAV_DEPTH}
        />
      ))}
    </List>
  );
});

export default Sidebar;
