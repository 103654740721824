import lookupVehicleRegNumberAPI from 'api/vehicleRegNumberLookup';
import {
  RESET_VEHICLE_REG_NUMBER,
  FETCH_VEHICLE_REG_NUMBER,
  FETCH_VEHICLE_REG_NUMBER_SUCCESS,
  FETCH_VEHICLE_REG_NUMBER_FAIL,
  UNSET_VEHICLE_REG_NUMBER_REDIRECT
} from 'actions/vehicleRegNumber/actionTypes';
import type {
  ResetVehicleRegNumberAction,
  FetchVehicleRegNumberAction,
  FetchVehicleRegNumberSuccessAction,
  FetchVehicleRegNumberFailAction,
  FetchVehicleRegNumberDataAction,
  UnsetVehicleRegNumberToggleAction
} from 'actions/vehicleRegNumber/types';

const resetVehicleRegNumber: ResetVehicleRegNumberAction = () => ({
  type: RESET_VEHICLE_REG_NUMBER
});

const fetchVehicleRegNumber: FetchVehicleRegNumberAction = () => ({
  type: FETCH_VEHICLE_REG_NUMBER
});

const fetchVehicleRegNumberSuccess: FetchVehicleRegNumberSuccessAction = payload => ({
  type: FETCH_VEHICLE_REG_NUMBER_SUCCESS,
  payload
});

const fetchVehicleRegNumberFail: FetchVehicleRegNumberFailAction = payload => ({
  type: FETCH_VEHICLE_REG_NUMBER_FAIL,
  payload
});

const unsetVehiclRegNumberRedirect: UnsetVehicleRegNumberToggleAction = () => ({
  type: UNSET_VEHICLE_REG_NUMBER_REDIRECT
});

const fetchVehicleRegNumberData: FetchVehicleRegNumberDataAction = ({
  vrn,
  indexReference
}) => async dispatch => {
  dispatch(fetchVehicleRegNumber());

  try {
    const data = await lookupVehicleRegNumberAPI(vrn, indexReference);

    dispatch(fetchVehicleRegNumberSuccess({ data: data.entities[0] || {} }));
  } catch (error) {
    dispatch(fetchVehicleRegNumberFail({ error: error.message }));
  }
};

export {
  resetVehicleRegNumber,
  fetchVehicleRegNumberData,
  fetchVehicleRegNumber,
  fetchVehicleRegNumberSuccess,
  fetchVehicleRegNumberFail,
  unsetVehiclRegNumberRedirect
};
