import './styles.scss';

import * as React from 'react';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextField } from 'final-form-material-ui';
// $FlowFixMe
import { Form, Field } from 'react-final-form';
import Button from 'components/Button';
import MenuItem from 'components/MenuItem';
import search, { setSearchSelection } from 'actions/search';
import { activeSearchSelector, filterSelector } from 'selectors/search';
import useActions from 'hooks/useActions';
import useFiltering from 'app/search/useFiltering';
import { searchFields } from 'app/search/config';
import { SEARCH_PRODUCTS_PATH } from 'routes/paths';

const SearchBar = () => {
  const fetchSearch = useActions(search);
  const setSearchBoundary = useActions(setSearchSelection);
  const currentSearch = useSelector(activeSearchSelector);
  const currentSearchFilter = useSelector(filterSelector);
  const [baseArgs] = useFiltering();

  const onSubmit = values => {
    let args = {
      ...baseArgs,
      page: 0,
      [baseArgs.isProductSearch
        ? baseArgs.search.selection
        : 'query']: values.searchField,
      search: { ...baseArgs.search, term: values.searchField }
    };

    args = Object.assign(
      args,
      ...Object.keys(currentSearchFilter)
        .filter(filterName => !!currentSearchFilter[filterName])
        .map(filterName => ({
          [filterName]: currentSearchFilter[filterName]
        }))
    );

    fetchSearch(args);
    navigate(`/${SEARCH_PRODUCTS_PATH}`);
  };

  const onSelect = evt => {
    setSearchBoundary({ selection: evt.target.value });
  };

  return (
    <Box className="searchbar margin__top--8 margin__bottom--8">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, pristine, values, dirty, errors }) => (
          <form noValidate onSubmit={handleSubmit} className="searchbar__form">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="stretch">
              <Box width="150px" mr={1}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={'formcontrol'}>
                  <InputLabel htmlFor="search-props">Search by</InputLabel>
                  <Field name="searchProps">
                    {({ input }) => (
                      <Select
                        {...input}
                        value={currentSearch.selection}
                        onChange={onSelect}
                        className={'searchbar__select'}
                        input={
                          <OutlinedInput
                            labelWidth={70}
                            className={'outlined'}
                            name={input.name}
                            id="search-props"
                          />
                        }>
                        {searchFields.map(({ name, label }) => (
                          <MenuItem key={name} value={name}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Field>
                </FormControl>
              </Box>

              <Box width="250px" className={'search-input-container'}>
                <Field
                  required
                  fullWidth
                  variant="outlined"
                  name="searchField"
                  component={TextField}
                  type="text"
                  label="Search details"
                  defaultValue={currentSearch.term}
                  disabled={!currentSearch.selection}
                  InputProps={{ 'data-field': 'searchbar' }}
                  className={'formcontrol__input'}
                />
                <Button
                  variant="text"
                  type="submit"
                  className="btn btn--primary btn--medium btn-search pad__top--8"
                  disabled={!values.searchField || submitting}>
                  <SearchIcon fontSize="large" />
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default SearchBar;
