import './styles.scss';
import type { Props } from 'app/types';

import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { isEmpty, toLower, get } from 'lodash/fp';
import classNames from 'classnames';
import { Backdrop, Drawer, Fab, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Header from 'components/Header';
import Breadcrumbs from 'components/Breadcrumbs';
import Progress from 'components/Spinner';
import Sidebar from 'app/sidebar';
import { unsetSelectedNavigation } from 'actions/navigation';
import { breadcrumbsSelector } from 'selectors/breadcrumbs';
import { sessionActionSelector } from 'selectors/session';
import { domainSelector } from 'selectors/searchDomain';
import { loadingSelector } from 'selectors/search';
import { productLoadingSelector } from 'selectors/products';
import { unsetVehiclRegNumberRedirect } from 'actions/vehicleRegNumber';
import {
  setSearchDomainReferenceFromNav,
  unsetSearchDomain
} from 'actions/searchDomain';
import { pauseSession, pendingResumeSession } from 'actions/session';
import PartsFinderRouter from 'routes';
import { SESSION_PATH, ROOT_PATH } from 'routes/paths';
import useSession from 'hooks/useSession';
import useActions from 'hooks/useActions';
import useSidebar from 'hooks/useSidebar';
import useTill from 'hooks/useTill';
import { CYCLE_REPUBLIC, NUMBER_PLATES, NUMBER_PLATE } from 'libs/constants';
import trimSpace from 'libs/trimSpace';

const { Fragment, useEffect } = React;

const PartsFinder: React.StatelessFunctionalComponent<Props> = () => {
  const [isSidebarOpen, toggleSidebar] = useSidebar();

  const { loggedIn, till } = useTill();
  const { paused, active } = useSession();
  const pauseCurrentSession = useActions(pauseSession);
  const resumeSessionPending = useActions(pendingResumeSession);
  const unsetLookupResultsRedirect = useActions(unsetVehiclRegNumberRedirect);
  const unsetSelectedNav = useActions(unsetSelectedNavigation);
  const unsetDomain = useActions(unsetSearchDomain);
  const dispatchActiveDomain = useDispatch();

  const activeDomain = useSelector(domainSelector);
  const isSearching = useSelector(loadingSelector);
  const isFetchingProducts = useSelector(productLoadingSelector);

  const {
    newSession,
    pausedSession,
    resumedSession,
    pendingResumeSession: sessionResumePending
  } = useSelector(sessionActionSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector) || [];

  const noSessionActivity =
    !newSession && !resumedSession && !pausedSession && !sessionResumePending; // everything is false

  const showSessionBasedComponents =
    loggedIn &&
    (newSession || resumedSession) &&
    !pausedSession &&
    !sessionResumePending;

  const isCycleRepublic =
    !isEmpty(till) &&
    toLower(trimSpace(till.store_type)) === toLower(CYCLE_REPUBLIC);

  const isNumberPlates = !isEmpty(till) && get('CatID', till) === NUMBER_PLATE;

  useEffect(() => {
    const appWrapper = document.querySelector('#partsfinder-root');
    if (!loggedIn) {
      if (!!appWrapper) {
        appWrapper.classList.add('no-auth');
      }
    } else {
      if (!!appWrapper) {
        appWrapper.classList.remove('no-auth');
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && isNumberPlates && activeDomain !== NUMBER_PLATES) {
      dispatchActiveDomain(
        setSearchDomainReferenceFromNav(NUMBER_PLATES, true, false)
      );
    }
  }, [activeDomain, dispatchActiveDomain, isNumberPlates, loggedIn]);

  const navigateToSessionRouter = path => {
    navigate(`/${SESSION_PATH}`, { replace: true });
  };

  const onPauseSession = () => {
    pauseCurrentSession();
    navigateToSessionRouter(SESSION_PATH);
  };

  const onResumeSession = () => {
    resumeSessionPending();
    navigateToSessionRouter(SESSION_PATH);
  };

  const onRootNavigation = () => {
    unsetLookupResultsRedirect();
    unsetSelectedNav();
    unsetDomain();
    navigateToSessionRouter(SESSION_PATH);
  };

  return (
    <Fragment>
      <Header
        noSession={noSessionActivity}
        resumedSession={resumedSession}
        hasPaused={!isEmpty(paused)}
        pendingResume={sessionResumePending}
        pausedSession={pausedSession}
        activeSession={!isEmpty(active)}
        onPauseSession={onPauseSession}
        onResumeSession={onResumeSession}
        onLogoClick={onRootNavigation}
        auth={{
          loggedIn,
          username: loggedIn ? till.colleague_name : ''
        }}
      />
      <div className="drawer__container">
        {showSessionBasedComponents && (
          <Drawer
            transitionDuration={0}
            className="drawer"
            variant="persistent"
            anchor="left"
            open={isSidebarOpen}>
            <Sidebar
              isCycleRepublic={isCycleRepublic}
              isNumberPlates={isNumberPlates}
            />
          </Drawer>
        )}

        <div
          className={classNames('content', {
            'nav_collapse--false': isSidebarOpen,
            'nav_collapse--true': !isSidebarOpen
          })}>
          <div className="toggle">
            {showSessionBasedComponents && isSidebarOpen && (
              <Fab
                className="toggle__button"
                color="secondary"
                size="small"
                aria-label="hide nav"
                onClick={toggleSidebar}>
                <ChevronLeftIcon />
              </Fab>
            )}

            {showSessionBasedComponents && !isSidebarOpen && (
              <Fab
                className="toggle__button"
                color="secondary"
                size="small"
                aria-label="open nav"
                onClick={toggleSidebar}>
                <MenuIcon />
              </Fab>
            )}
          </div>

          <div className={classNames('content-data')}>
            {showSessionBasedComponents && (
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignContent="center">
                <Box flexGrow={1} className="crumb__container">
                  <Breadcrumbs
                    data={[
                      {
                        path: ROOT_PATH,
                        onClick: onRootNavigation,
                        label: 'Home',
                        isHome: true
                      },
                      ...breadcrumbs
                    ]}
                  />
                </Box>
              </Box>
            )}

            {isSearching ? (
              <div className="margin__top--24">
                <Progress visible />
              </div>
            ) : (
              <PartsFinderRouter />
            )}
          </div>
        </div>
      </div>
      <Backdrop className="backdrop" open={isFetchingProducts || isSearching} />
    </Fragment>
  );
};

export default PartsFinder;
