import type { SearchDomainState, IndexReference } from 'types';
import type { Reducer } from 'reducers/types';
import type {
  SetSearchDomainType,
  UnsetSearchDomainType
} from 'actions/searchDomain/types';

import {
  SET_SEARCH_DOMAIN,
  UNSET_SEARCH_DOMAIN,
  SET_NAV_SEARCH_DOMAIN
} from 'actions/searchDomain/actionTypes';
import { initState } from 'reducers/index';

const searchDomainReducer: Reducer<
  SearchDomainState,
  {
    type: UnsetSearchDomainType | SetSearchDomainType,
    payload?: {
      indexReference: IndexReference,
      domain: string,
      autoSelectCategory: boolean
    }
  }
> = (state = initState.searchDomain, { type, payload }) => {
  switch (type) {
    case UNSET_SEARCH_DOMAIN:
      return { ...initState.searchDomain };
    case SET_NAV_SEARCH_DOMAIN:
    case SET_SEARCH_DOMAIN:
      if (!!payload) {
        const { domain, indexReference, autoSelectCategory } = payload;
        return {
          ...state,
          domain,
          indexReference,
          autoSelectCategory
        };
      }
      return state;
    default:
      return state;
  }
};

export default searchDomainReducer;
