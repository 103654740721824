import type {
  SessionClearType,
  SessionPauseType,
  SessionResumeType,
  SessionStartType,
  SessionEndType,
  SessionStartWithHistoryType,
  SessionPendingResumeType
} from 'actions/session/types';

export const SESSION_CLEAR: SessionClearType = '@@session/SESSION_CLEAR';

export const SESSION_PAUSE: SessionPauseType = '@@session/SESSION_PAUSE';

export const SESSION_RESUME: SessionResumeType = '@@session/SESSION_RESUME';

export const SESSION_START: SessionStartType = '@@session/SESSION_START';

export const SESSION_START_WITH_HISTORY: SessionStartWithHistoryType =
  '@@session/SESSION_START_WITH_HISTORY';

export const SESSION_END: SessionEndType = '@@session/SESSION_END';

export const SESSION_PENDING_RESUME: SessionPendingResumeType =
  '@@session/SESSION_PENDING_RESUME';
