import type { Reducer } from 'reducers/types';
import type { VendorState, Vendor } from 'types';
import type {
  FetchVendors,
  FetchVendorsSuccess,
  FetchVendorsFail
} from 'actions/vendors/types';

import {
  FETCH_VENDORS,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_FAIL
} from 'actions/vendors/actionTypes';
import { initState } from 'reducers';

type ActionType = FetchVendors | FetchVendorsSuccess | FetchVendorsFail;

const vendorsReducer: Reducer<
  VendorState,
  {
    type: ActionType,
    payload: {
      vendors: Vendor[],
      error: string
    }
  }
> = (state = initState.vendors, { type, payload }) => {
  switch (type) {
    case FETCH_VENDORS:
      return { ...state, isLoading: true };
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        error: '',
        data: payload.vendors
      };
    case FETCH_VENDORS_FAIL:
      return {
        ...state,
        isLoading: false,
        fetchComplete: true,
        error: payload.error,
        data: []
      };
    default:
      return state;
  }
};

export default vendorsReducer;
